import DistributorInfoSection from 'components/common/sections/DistributorInfoSection';
import { useForm } from 'contexts/FormContext';
import EuFormShippingAddressSection from './EuFormShippingAddressSection';
import EuFormBillingAddressSection from './EuFormBillingAddressSection';

export default function EuForm({ data }) {
  // Context
  const { form } = useForm();

  // Contentful
  const { reference2, reference3, reference4 } = data.fields;

  return (
    <>
      <DistributorInfoSection data={reference2} />
      <EuFormShippingAddressSection data={reference4} />
      {!form.AddressIsTheSame && (
        <EuFormBillingAddressSection data={reference3} />
      )}
    </>
  );
}
