import { useEffect, useState } from "react";
import { getEntryByLocale } from "api/contentful";
import { useApp } from "contexts/AppContext";

export const useContentful = (entryId) => {
  const {
    state: { locale = "en-US" },
  } = useApp();
  const [data, setData] = useState();

  useEffect(() => {
    getEntryByLocale(entryId, locale).then((res) => {
      const { error } = res;
      !error && setData(res);
    });
  }, [entryId, locale]);

  return data;
};
