import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { defaultCnLanguage } from 'constants/language';
import { useApp } from 'contexts/AppContext';
import { FormContextProvider } from 'contexts/FormContext';
import CnMigrationFormPage from './migration-form';
import CnMigrationRequestRoutes from './migration-request/routes';

export default function CnMigrationRoutes() {
  // Custom Hooks
  const { dispatch } = useApp();

  // State
  const [hasDispatched, setHasDispatched] = useState(false);

  // Effects
  useEffect(() => {
    dispatch({ type: 'updateLocale', locale: defaultCnLanguage.locale });
    setHasDispatched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasDispatched) {
    return null;
  }

  return (
    <FormContextProvider market="cn">
      <Routes>
        <Route path="migration-form" element={<CnMigrationFormPage />} />
        <Route
          path="migration-request/*"
          element={<CnMigrationRequestRoutes />}
        />
      </Routes>
    </FormContextProvider>
  );
}
