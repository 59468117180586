import { Routes, Route } from 'react-router-dom';
import { FormContextProvider } from 'contexts/FormContext';
import SgMigrationFormPage from './migration-form';

export default function SgMigrationRoutes() {
  return (
    <FormContextProvider market="sg">
      <Routes>
        <Route path="migration-form" element={<SgMigrationFormPage />} />
      </Routes>
    </FormContextProvider>
  );
}
