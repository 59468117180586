import { Routes, Route } from "react-router-dom";
import { FormContextProvider } from "contexts/FormContext";
import HkcbMigrationFormPage from "./migration-form";

export default function HkcbMigrationRoutes(props) {
  return (
    <FormContextProvider market='hkcb'>
      <Routes>
        <Route
          path='migration-form'
          element={<HkcbMigrationFormPage {...props} />}
        />
      </Routes>
    </FormContextProvider>
  );
}
