import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { formApi } from 'api/form';
import { repApi } from 'api/rep';
import CnForm from 'components/cn/CnForm';
import CnBenefits from 'components/cn/CnBenefits';
import CnDetails from 'components/cn/CnDetails';
import FormFooter from 'components/common/footers/FormFooter';
import FormHeader from 'components/common/headers/FormHeader';
import Layout from 'components/common/layouts/Layout';
import Loader from 'components/common/loaders/Loader';
import { useApp } from 'contexts/AppContext';
import { useForm } from 'contexts/FormContext';
import { useModal } from 'contexts/ModalContext';
import { useContentful } from 'hooks/useContentful';
import config from 'config';

export default function CnMigrationRequestPage() {
  // Context
  const {
    state: { locale, rep },
  } = useApp();
  const { form, market } = useForm();
  const { open } = useModal();

  // Custom Hooks
  const data = useContentful('784o2UkBEuy1CtVJzFQPpB'); // (Paid Version) China Request Page

  // State
  const [previousPhoneUsed, setPreviousPhoneUsed] = useState('');
  const [hasCheckedForUniqueMigration, setHasCheckedForUniqueMigration] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks when the saveMigrationData call is in progress

  useEffect(() => {
    const isUniqueMigration = async ({ RepID }) => {
      const res = await repApi.testForUniqueMigration({ RepID });
      const { shipPhone } = res;

      if (!isEmpty(shipPhone)) {
        setPreviousPhoneUsed(shipPhone);
      } else {
        setPreviousPhoneUsed('');
      }

      setHasCheckedForUniqueMigration(true);
    };

    if (!isEmpty(rep)) {
      const { RepID } = rep;
      isUniqueMigration({ RepID });
    }
  }, [rep]);

  if (!data || !hasCheckedForUniqueMigration) {
    return null;
  }

  const onConfirm = async () => {
    try {
      setIsSubmitting(true);
      setLoading(true);
      const migrationStatus = await formApi.savePaidMigrationData({
        market,
        locale,
        rep,
        form,
      });
  
      if (typeof migrationStatus === 'string') {
        if (migrationStatus === 'Created new user') {
          console.log("Migration saved, creating order");
          const res = await formApi.createOrderMigration({ rep });
          if (typeof res === 'object') {
            const { guid, onlineOrderId } = res;
            if (guid && onlineOrderId) {
              // Query Parameters
              const { DefaultLocaleID } = rep;
  
              // URL
              const params = new URLSearchParams();
              params.set("guid", guid);
              params.set("OnlineOrderID", onlineOrderId);
              params.set("LocaleID", DefaultLocaleID);
              params.set("OrderType", 'R');
              params.set("JSAddressPage", '1');
              params.set("RevolutionOrder", '1');
              const url = `${config.REACT_APP_BYDESIGN_PAYMENT_BASE_URL}/Shopping/ShoppingCart_LoadPage.asp?${params.toString()}`;
  
              // Redirect
              window.location.href = url;
              return;
            } else {
              throw new Error(`Guid: ${guid} or OnlineOrderId: ${onlineOrderId} were not returned from createOrderMigration.`);
            }
          }
        } else {
          // TODO: Error handling.
        }
      }
      throw new Error(migrationStatus); // Throw so we reenable the save button
    } catch (err) {
      console.error("Migration failed", err);
      setIsSubmitting(false);
    } finally {
      setLoading(false);
    }
  };

  const onFormSubmit = () => {
    open({
      type: 'confirm-migration',
      props: { market: `${market}-paid` },
      callback: onConfirm,
    });
  };

  const { banner1Ref, banner2Ref, banner3Ref, banner4Ref } = data.fields;

  return (
    <Layout data={banner1Ref}>
      <Loader active={loading} />
      <FormHeader data={banner1Ref} />
      <CnBenefits data={banner3Ref} />
      <CnForm data={banner2Ref} previousPhoneUsed={previousPhoneUsed} />
      <CnDetails data={banner4Ref} />
      <FormFooter data={banner1Ref} onFormSubmit={onFormSubmit} isSubmitting={isSubmitting} />
    </Layout>
  );
}
