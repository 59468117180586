import ShadowCard from 'components/common/cards/ShadowCard';

export default function CnDetails({ data }) {
  const { title, item1, item2, item3, item4, item5 } = data.fields;

  return (
    <ShadowCard>
      <div className="center">
        <p className="text-center wide-desktop">
          <span className="bold">{title}</span>
        </p>
      </div>
      <div className="mig-details">
        <Details title={item1} items={[item2, item3, item4, item5]} />
      </div>
    </ShadowCard>
  );
}

const Details = ({ title, items = [] }) => {
  return (
    <>
      <p>
        <span className="bold">{title}</span>
      </p>
      {items.map((item, index) => (
        <p key={index}>• {item}</p>
      ))}
    </>
  );
};
