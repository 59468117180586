import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import GuidAuth from "components/common/auth/GuidAuth";
import BeMigrationRoutes from "pages/be/routes";
import CnMigrationRoutes from "pages/cn/routes";
import DeMigrationRoutes from "pages/de/routes";
import GbMigrationRoutes from "pages/gb/routes";
import JpMigrationRoutes from "pages/jp/routes";
import KrMigrationRoutes from "pages/kr/routes";
import MyMigrationRoutes from "pages/my/routes";
import NlMigrationRoutes from "pages/nl/routes";
import SgMigrationRoutes from "pages/sg/routes";
import ThMigrationRoutes from "pages/th/routes";
import HkcbMigrationRoutes from "pages/hkcb/routes";
import HomePage from "pages";
import NotFound from "pages/404";
import { config as constantsConfig } from "constants/config";
import config from 'config'

export default function Router() {
  return (
    <BrowserRouter>
      <GuidAuth>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/migrations' element={<HomePage />} />
          <Route path='/be/*' element={<BeMigrationRoutes />} />
          <Route path='/cn/*' element={<CnMigrationRoutes />} />
          <Route path='/de/*' element={<DeMigrationRoutes />} />
          <Route path='/gb/*' element={<GbMigrationRoutes />} />
          <Route path='/jp/*' element={<JpMigrationRoutes />} />
          <Route path='/kr/*' element={<KrMigrationRoutes />} />
          <Route path='/my/*' element={<MyMigrationRoutes />} />
          <Route path='/nl/*' element={<NlMigrationRoutes />} />
          <Route path='/sg/*' element={<SgMigrationRoutes />} />
          <Route path='/th/*' element={config?.FEATURE_FLAG_THAILAND ? <ThMigrationRoutes /> : <NotFoundWithStatus />} />
          <Route
            path='/hkcb/*'
            element={<HkcbMigrationRoutes config={constantsConfig.markets[9]} />}
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </GuidAuth>
    </BrowserRouter>
  );
}

// Wrap NotFound component to set 404 status
const NotFoundWithStatus = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // This will set the 404 status code
    if (window.location.pathname !== "/404") {
      window.history.replaceState({}, '', "/404");
    }
  }, [navigate]);

  return <NotFound />;
};
