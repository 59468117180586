import _axios from 'axios';
import config from 'config';

let axios = _axios.create({
  headers: {
    'x-session-start': new Date().toISOString()
  }
})

// GET
export const get = async (endpoint, params = {}) => {
  try {
    const response = await axios.get(
      `${config.REACT_APP_API_URL}${endpoint}`,
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// PUT
export const put = async (endpoint, params = {}) => {
  try {
    const response = await axios.put(
      `${config.REACT_APP_API_URL}${endpoint}`,
      params
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// POST
export const post = async (endpoint, params = {}) => {
  try {
    const response = await axios.post(
      `${config.REACT_APP_API_URL}${endpoint}`,
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// DELETE
export const remove = async (endpoint, params = {}) => {
  try {
    const response = await axios.post(
      `${config.REACT_APP_API_URL}${endpoint}`,
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
