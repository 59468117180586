import TextInput from 'components/common/inputs/TextInput';
import { inputIdFromNameMap } from 'helpers/form';

export default function TextInputForm({
  form,
  validateForm,
  inputs,
  inputNames = [],
  inputPatterns = [],
  inputBlurPatterns = [],
  inputOnChange,
  inputRequired = [],
  inputDynamicHelperText= [],
}) {
  return inputs.map((inputModel, index) => {
    const inputName = inputNames[index];
    const inputPattern = inputPatterns[index];
    const inputBlurPattern = inputBlurPatterns[index];
    const inputId = inputIdFromNameMap[inputName] || inputName;
    const dynamicHelperText = inputDynamicHelperText[index]
    const errorText = inputModel.fields?.errorText;
    const required = inputRequired[index];
    const { name, helperText } = inputModel.fields;
    return (
      <TextInput
        inputId={inputId}
        inputName={inputName}
        inputValue={form[inputId]}
        inputOnChange={inputOnChange}
        inputOnBlur={validateForm}
        onBlurPattern={inputBlurPattern}
        inputPattern={inputPattern}
        label={name}
        helperText={helperText}
        inputErrorText={errorText}
        required={required}
        dynamicHelperText={dynamicHelperText}
        key={index}
      />
    );
  });
}
