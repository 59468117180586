import { Routes, Route } from 'react-router-dom';
import { FormContextProvider } from 'contexts/FormContext';
import DeMigrationFormPage from './migration-form';

export default function DeMigrationRoutes() {
  return (
    <FormContextProvider market="de">
      <Routes>
        <Route path="migration-form" element={<DeMigrationFormPage />} />
      </Routes>
    </FormContextProvider>
  );
}
