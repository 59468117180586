import HkcbFormBillingAddressSection from "./HkcbFormBillingAddressSection";
import HkcbFormDistributorInfoSection from "./HkcbFormDistributorInfoSection";
import HkcbFormShippingAddressSection from "./HkcbFormShippingAddressSection";

export default function HkcbForm({ data, config }) {
  const { banner2Ref, banner3Ref, banner4Ref } = data.fields;

  return (
    <>
      <HkcbFormDistributorInfoSection data={banner2Ref} config={config} />
      <HkcbFormShippingAddressSection data={banner4Ref} config={config} />
      <HkcbFormBillingAddressSection data={banner3Ref} config={config} />
    </>
  );
}
