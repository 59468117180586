export const confirmModalIdByMarketMap = {
  be: "3TJAkDESfzNh3HjvEQ592C",
  "cn-free": "1qHN6vG7PDZm180n90pJ5w",
  "cn-paid": "4xzJ5CtVfc5mQQsjNnlYtI",
  de: "1olmsNorlNcsDjS0pcO0Eg",
  gb: "7e6NRYOzBC1rXRyIxZ0RMO",
  jp: "2Ig7iPN7BtlzcGuochFLAI",
  kr: "1vr44U7AgXEk05B1sr0Pqp",
  my: "2jW2CRRJhSoTdcvARiK8Dp",
  nl: "4ZF1ZhTvNgRNwPYWgCRLsz",
  sg: "442J5QjeVawRTnJmoGE5QH",
  hkcb: "3k1oBoGR46ZJgn8gjUdc98",
  th: "5xdrcJ48fexJCoGQQvOQ5B"
};
