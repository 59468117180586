import { useForm } from 'contexts/FormContext';
import JpFormBillingAddressSection from './JpFormBillingAddressSection';
import JpFormDistributorInfoSection from './JpFormDistributorInfoSection';
import JpFormShippingAddressSection from './JpFormShippingAddressSection';

export default function JpForm({ data }) {
  // Context
  const { form } = useForm();

  const { banner2Ref, banner3Ref, banner4Ref } = data.fields;

  return (
    <>
      <JpFormDistributorInfoSection data={banner2Ref} />
      <JpFormShippingAddressSection data={banner4Ref} />
      {!form.AddressIsTheSame && (
        <JpFormBillingAddressSection data={banner3Ref} />
      )}
    </>
  );
}
