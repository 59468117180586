export const pathToMarketMap = {
  cn: "China",
  jp: "Japan",
  sg: "Singapore",
  my: "Malaysia",
  de: "Germany",
  be: "Belgium",
  gb: "United Kingdom",
  nl: "Netherlands",
  kr: "Korea",
  hkcb: "Hong Kong Cross Market",
  th: "Thailand",
};

export const marketToPathMap = {
  China: "cn",
  Japan: "jp",
  Singapore: "sg",
  Malaysia: "my",
  Germany: "de",
  Belgium: "be",
  "United Kingdom": "gb",
  Netherlands: "nl",
  "Korea (the Republic of)": "kr",
  "Hong Kong Cross Market": "hkcb",
  Thailand: "th",
};
