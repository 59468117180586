import { useForm } from 'contexts/FormContext';
import SgFormDistributorInfoSection from './SgFormDistributorInfoSection';
import SgFormShippingAddressSection from './SgFormShippingAddressSection';
import SgFormBillingAddressSection from './SgFormBillingAddressSection';

export default function SgForm({ data }) {
  // Context
  const { form } = useForm();

  // Contentful
  const { banner2Ref, banner3Ref, banner4Ref } = data.fields;

  return (
    <>
      <SgFormDistributorInfoSection data={banner2Ref} />
      <SgFormShippingAddressSection data={banner4Ref} />
      {!form.AddressIsTheSame && (
        <SgFormBillingAddressSection data={banner3Ref} />
      )}
    </>
  );
}
