export default function CnConfirmation({ data }) {
  const {
    backgroundImage: {
      fields: {
        file: { url: imgSrc },
        title: imgAlt,
      },
    },
    title,
    item1,
    buttonText,
    buttonUrl,
  } = data.fields;
  return (
    <>
      <section className="intro-text center mt-20">
        <img src={imgSrc} alt={imgAlt} className="check-img" />
        <h1 className="text-center wide-desktop">{title}</h1>
        <p className="text-center wide-desktop">{item1}</p>
      </section>
      <button
        data-testid="req_submitted"
        className="button"
        type="button"
        onClick={() => (window.location.href = buttonUrl)}
      >
        {buttonText}
      </button>
    </>
  );
}
