import { useEffect, useMemo } from 'react';
import ShadowCard from 'components/common/cards/ShadowCard';
import SelectInput from 'components/common/inputs/SelectInput';
import TextInput from 'components/common/inputs/TextInput';
import {
  hangulRegex,
  koreanAddressRegex,
  fiveDigitsRegex,
} from 'constants/regex';
import BlueSwitchCheckboxInput from 'components/common/inputs/BlueSwitchCheckboxInput';
import { useForm } from 'contexts/FormContext';

export default function KrFormShippingAddressSection({ states = [], data }) {
  // Contexts
  const { form, setForm, inputOnChange, validateForm } = useForm();

  // Effects
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.ShipState, form.AddressIsTheSame]);

  // Contentful
  const {
    title,
    text1,
    text2,
    text3,
    reference1: {
      fields: { listArray },
    },
  } = data.fields;

  const onSelectChange = useMemo(
    () => (e) => {
      const { id } = e.target;
      setForm({ ...form, ShipState: id });
    },
    [form, setForm]
  );

  return (
    <ShadowCard label={title}>
      <div className='form-max flex-20 column' id="address-section">
        <p className='address-country'>
          {text1}
          <strong>{text2}</strong>
        </p>
        <SelectInput
          selectId='shipping-address-state'
          labelText={listArray[0].fields?.name}
          options={states}
          value={form.ShipState}
          onChange={onSelectChange}
        />
        <TextInput
          inputId='ShipCity'
          inputName='shipping-address-city'
          inputPattern={hangulRegex}
          inputValue={form.ShipCity}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          label={listArray[1]?.fields?.name}
          helperText={listArray[1]?.fields?.helperText}
          inputErrorText={listArray[1]?.fields?.errorText}
          dynamicHelperText
          required
        />
        <TextInput
          inputId='ShipStreet1'
          inputName='shipping-address-line-1'
          onBlurPattern={koreanAddressRegex}
          inputValue={form.ShipStreet1}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          label={listArray[2]?.fields?.name}
          helperText={listArray[2]?.fields?.helperText}
          inputErrorText={listArray[2]?.fields?.errorText}
          dynamicHelperText
          required
        />
        <TextInput
          inputId='ShipStreet2'
          inputName='shipping-address-line-2'
          onBlurPattern={koreanAddressRegex}
          inputValue={form.ShipStreet2}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          label={listArray[3]?.fields?.name}
          helperText={listArray[3]?.fields?.helperText}
          inputErrorText={listArray[3]?.fields?.errorText}
          dynamicHelperText
        />
        <TextInput
          inputId='ShipPostalCode'
          inputName='shipping-address-postal'
          inputValue={form.ShipPostalCode}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          onBlurPattern={form.ShipPostalCode ? fiveDigitsRegex : ''}
          label={listArray[4]?.fields?.name}
          helperText={listArray[4]?.fields?.helperText}
          inputErrorText={listArray[4]?.fields?.errorText}
          mask='zipCode'
        />
        <BlueSwitchCheckboxInput
          inputId='AddressIsTheSame'
          inputName='AddressIsTheSame'
          inputAriaLabel='AddressIsTheSame'
          inputChecked={form.AddressIsTheSame}
          inputOnChange={inputOnChange}
          label={text3}
          required
        />
      </div>
    </ShadowCard>
  );
}
