import { post } from './request';

export const repApi = {
  testForUniqueMigration: async ({ RepID }) => {
    try {
      const res = await post('/api/testForUniqueMigration', { repId: RepID });

      if (typeof res === 'object' && res.recordset.length !== 0) {
        return res.recordset[0];
      } else {
        return {};
      }
    } catch {
      console.error('Problem checking if user has already migrated.');
    }
  },
};
