// Switch lookup table for respective listId to get all languages available for each market.
// TODO: Replace this with an api call to get the list id.
export const listIdByMarketMap = {
  cn: 2,
  jp: 3,
  sg: 4,
  my: 5,
  de: 12,
  be: 13,
  nl: 14,
  gb: 15,
  kr: 16,
  hkcb: 17,
  th: 18,
};
