import { ReactComponent as LogoSVG } from 'assets/svg/logo.svg';
import LanguageSelector from 'components/common/selectors/LanguageSelector';

export default function SiteHeader({ enableLanguageSelector = true }) {
  return (
    <nav className="site-nav stacked">
      <ul>
        <li>
          <LogoSVG />
        </li>
        {enableLanguageSelector && <LanguageSelector />}
      </ul>
    </nav>
  );
}
