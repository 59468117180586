import {
  BE,
  CN,
  DE,
  GB,
  JP,
  KR,
  MY,
  NL,
  SG,
  TH,
} from "country-flag-icons/react/3x2";

export default function MarketFlag({ market }) {
  const style = { height: "26px", paddingRight: "10px" };
  // TODO: Find a way to return dynamically imported components from the library via market name.
  const MarketFlag = {
    be: <BE style={style} />,
    cn: <CN style={style} />,
    hkcb: <CN style={style} />,
    de: <DE style={style} />,
    gb: <GB style={style} />,
    jp: <JP style={style} />,
    kr: <KR style={style} />,
    my: <MY style={style} />,
    nl: <NL style={style} />,
    sg: <SG style={style} />,
    th: <TH style={style} />,
  };

  return MarketFlag[market];
}

export const MarketFlagComponent = {
  be: BE,
  cn: CN,
  hkcb: CN,
  de: DE,
  gb: GB,
  jp: JP,
  kr: KR,
  my: MY,
  nl: NL,
  sg: SG,
};
