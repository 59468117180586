import { createContext, useContext, useReducer } from 'react';
import { initialState } from 'constants/app';

const AppContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateLocale':
      return { ...state, locale: action.locale };
    case 'updateRep':
      return { ...state, rep: { ...action.rep } };
    default:
      throw new Error(`Unhandled action type in AppContext: ${action.type}`);
  }
};

// Used to store any variables that manage ui/ux in the application. (e.g. locale, repDID, etc...)
const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to consume context.
const useApp = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useApp must be used within a AppContextProvider');
  }

  return context;
};

export { AppContextProvider, useApp };
