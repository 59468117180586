import { useContentful } from "hooks/useContentful";
import Modal from "./Modal";

export default function IllegalCharactersModal({ close }) {
  const data = useContentful("6b5zkIv7zgXwuNV8cNLHYm"); // Illegal Characters Modal Info

  if (!data) {
    return null;
  }

  const { title, item1, buttonText } = data.fields;

  return (
    <Modal id="illegal-characters-modal" close={close}>
      <section className="intro-text center">
        <h2 className="mt-20">{title}</h2>
        <p className="text-center wide-desktop">{item1}</p>
      </section>
      <div className="mt-20 center">
        <button
          data-testid={`${title?.replace(/\W/g, "").toLowerCase()}_btn`}
          className="button"
          onClick={() => close()}
        >
          {buttonText}
        </button>
      </div>
    </Modal>
  );
}
