import { useContentful } from 'hooks/useContentful';
import Modal from './Modal';

export default function BankErrorModal({ errorMessage, close, callback }) {
  const data = useContentful('3i2w29xXXhi3d8Ul0fyqXw'); // Error Modal Info

  if (!data) {
    return null;
  }

  const {
    title,
    item1: errorText,
    item2: bankNameBullet,
    item3: nameOnBankBullet,
    item4: accountNumberBullet,
    buttonText,
  } = data.fields;

  const errorMessageBullets = [bankNameBullet, nameOnBankBullet, accountNumberBullet];

  return (
    <Modal id="error-modal" cancelType={false}>
      <section className="text-left modal-title">
        <h2>{title}</h2>
      </section>
      <section className="modal-content">
        {errorMessage && <p className="text-left">{errorText}</p>}
      </section>
      <section className="modal-content">
        <ul>
          {errorMessageBullets.map((bullet, idx) => (
            <li key={idx}>{bullet}</li>
          ))}
        </ul>
      </section>
      <div className="modal-footer">
        <button
          data-testid={`${buttonText?.replace(/\W/g, '').toLowerCase()}_btn`}
          className="button"
          onClick={() => {
            close()
            callback()
          }}
        >
          {buttonText}
        </button>
      </div>
    </Modal>
  );
}
