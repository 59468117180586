import ShadowCard from "components/common/cards/ShadowCard";
import TextInputForm from "components/common/forms/TextInputForm";
import { useForm } from "contexts/FormContext";

export default function HkcbFormBillingAddressSection({ data }) {
  // Context
  const { form, inputOnChange, validateForm } = useForm();

  // Contentful
  const { title, text1, text2, inputModelArray } = data.fields;

  // inputNames need to match the order of models in inputModelArray.
  const inputNames = [
    "billing-address-line-1",
    "billing-address-line-2",
    "billing-address-city",
    "billing-address-state",
  ];

  return (
    <ShadowCard label={title}>
      <div className='form-max flex-20 column'>
        <p className='address-country'>
          {text1}
          <strong>{text2}</strong>
        </p>
        <TextInputForm
          form={form}
          validateForm={validateForm}
          inputs={inputModelArray}
          inputNames={inputNames}
          inputOnChange={inputOnChange}
        />
      </div>
    </ShadowCard>
  );
}
