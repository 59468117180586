export const hangulRegex = /^[\u3131-\uD79D ]*$/;

export const koreanAddressRegex =
  /^[\u3131-\uD79D0-9\s\-().,;:/]*([a-z][\u3131-\uD79D0-9\s-().,;:/]*){0,3}$/i;

export const thirteenDigitsRegex = /^\d+(-\d+)*$/;

export const tenToThirteenDigitsAndHyphenRegex =
  /^(?![^-]+-[^-]+-)[0-9- ]{10,14}$/;

export const fiveDigitsRegex = /^\d{5}$/;

export const numericRegex = /^[0-9]*$/;

export const koreaDateRegex =
  /^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/;

export const chineseCharactersDigitsSymbols = /^[\p{Script=Han}\d .'\-#@%&/]*$/u;
export const chineseCharactersDigitsSymbolsMax5English = /^[\p{Script=Han}\d .'\-#@%&/]*([a-z][\p{Script=Han}\d .'\-#@%&/]*){0,5}$/ui;

// https://stackoverflow.com/a/201378/4372579
export const emailRegex = 
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const thaiRegex = /^[a-zA-Z0-9ก-๙\s.,'-/]*$/;