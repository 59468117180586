import { defaultLanguage } from './language';
import config from 'config';

// QA
const qaTestRep = {
  RepID: 100694,
  RepDID: 'D0191929',
  Company: 'company',
  FirstName: 'qa-test',
  RomanizedCompany: '',
  RomanizedFirstName: '',
  RomanizedLastName: '',
  Email: 'dantest@newulife.com',
  BillStreet1: '3098 Executive Pkwy',
  BillStreet2: 'Suite 320',
  BillCity: 'LEHI',
  BillState: 'UT',
  BillPostalCode: '84043',
  BillCounty: 'UTAH',
  BillCountry: 'USA',
  ShipStreet1: '3098 Executive Pkwy',
  ShipStreet2: 'Suite 320',
  ShipCity: 'LEHI',
  ShipState: 'UT',
  ShipPostalCode: '84043',
  ShipCounty: 'UTAH',
  ShipCountry: 'USA',
  Phone1: '48393210321',
  PublicImageUrl: '100694.jpg?1614204778',
  ReplicatedSiteText: '',
  SponsorRepDID: '15F98',
  SponsorRepCount: 33,
  RankTypeID: 5,
  RepStatusTypeID: 1,
  RepStatusType: 'Active',
  URL: 'qa-test',
  UplineRepDID: 'D0186897',
  JoinDate: '2019-07-12T00:00:00',
  RepTypeID: 1,
  RenewalDate: '7/12/2020 12:00:00 AM',
  RepRenewalSKU: '',
  DefaultLocaleID: 1,
  PreferredCulture: 1,
  DateofBirth: '1970-01-01T00:00:00',
  DivisionID: 1,
  IsPasswordSet: true,
  BonusAccountType: 0,
  HasTaxID: true,
  RepEntityTypeID: 0,
  RepEntityType: 'Not Specified',
  PreferredPlacement: '',
};

// DEV
const devTestRep = {
  RepID: 867419,
  RepDID: '867419',
  FirstName: 'Usfname',
  LastName: 'Uslname',
  Email: 'fnameus7892310@testemail.com',
  BillStreet1: '350 S State St',
  BillStreet2: '',
  BillCity: 'Salt Lake City',
  BillState: 'UT',
  BillPostalCode: '84111-2303',
  BillCounty: 'Salt Lake',
  BillCountry: 'USA',
  ShipStreet1: '350 S State St',
  ShipStreet2: '',
  ShipCity: 'Salt Lake City',
  ShipState: 'UT',
  ShipPostalCode: '84111-2303',
  ShipCounty: 'Salt Lake',
  ShipCountry: 'USA',
  Phone1: '3333334444',
  Phone2: '',
  Phone3: '',
  Phone4: '',
  SponsorRepDID: 'D100',
  SponsorRepCount: 0,
  RankTypeID: 5,
  RepStatusTypeID: 3,
  RepStatusType: 'Withhold Commissions',
  URL: '',
  UplineRepDID: 'D10',
  JoinDate: '2022-02-15T00:00:00',
  RepTypeID: 1,
  RenewalDate: '2/15/2022 12:00:00 AM',
  RepRenewalSKU: '',
  DefaultLocaleID: 1,
  PreferredCulture: 1,
  DateofBirth: '1900-01-01T00:00:00',
  DivisionID: 1,
  IsPasswordSet: true,
  BonusAccountType: 0,
  HasTaxID: false,
  RepEntityTypeID: 1002,
  RepEntityType: 'Individual',
  PreferredPlacement: '',
};

// Set up default rep for development.
export const rep = config.NODE_ENV === 'development' ? qaTestRep : {};

export const initialState = {
  locale: defaultLanguage.locale,
  rep: rep,
};
