import Layout from "components/common/layouts/Layout";
import { useContentful } from "hooks/useContentful";
import { Grid } from "@newulife/common";

export default function NotFound() {
  // Custom Hooks
  // get contentful data - failure leaves page blank
  const data = useContentful("6G15l4gn15q0rBf9OWpS68"); // Migrations - 404 Page

  // if no data from contentful page is blank
  if (!data) {
    return null;
  }

  // Contentful
  const { title, buttonText, buttonUrl } = data?.fields;
  // add flag and flag styles to market

  return (
    <Layout enableLanguageSelector={false} data={data}>
      <Grid
        container
        direction="column"
        gap={4}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container 
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <h1>404</h1>
          <p>{title}</p>
        </Grid>

        <a href={buttonUrl} class="button">{buttonText}</a>
      </Grid>
    </Layout>
  );
}
