import { isEmpty } from 'lodash';
import { ReactComponent as CheckSVG } from 'assets/svg/check.svg';

export default function BlueSwitchCheckboxInput({
  inputId = '',
  inputName = '',
  inputAriaLabel = '',
  inputChecked = false,
  inputOnChange = () => {},
  label = '',
  children,
}) {
  const inputProps = {
    type: 'checkbox',
    'data-testid': inputId,
    id: inputId,
    name: inputName,
    'aria-label': inputAriaLabel,
    checked: inputChecked,
    onChange: (e) => inputOnChange(e),
  };

  return (
    <div className="blue--switch--container">
      <label className="blue--switch" htmlFor={inputId} tabIndex="0">
        <input {...inputProps} />
        <span className="slider round">
          <CheckSVG />
        </span>
      </label>
      <span className="switch-text">
        {!isEmpty(label) && <label htmlFor={inputId}>{label}</label>}
        {children}
      </span>
    </div>
  );
}
