import ShadowCard from "components/common/cards/ShadowCard";
import TextInput from "components/common/inputs/TextInput";
import { useApp } from "contexts/AppContext";
import { useForm } from "contexts/FormContext";
import { inputIdFromNameMap } from "helpers/form";

export default function JpFormDistributorInfoSection({ data }) {
  const {
    state: { rep },
  } = useApp();
  const { form, inputOnChange, validateForm } = useForm();

  const { RepDID, Email, FirstName, LastName } = rep;
  const { text1, text2, text3, text4, inputModelArray } = data.fields;

  // inputNames need to match the order of models coming from contentful.
  const inputNames = ["rfname", "rlname"];

  return (
    <ShadowCard>
      <div className="form-max flex-20 column">
        <p className="text-center wide-desktop">
          <span className="bold">{text1}</span>
          <br />
          {RepDID}
        </p>
        <p className="text-center wide-desktop">
          <span className="bold">{text2}</span>
          <br />
          {Email}
        </p>
        <p className="text-center wide-desktop">
          <span className="bold">{text3}</span>
          <br />
          {FirstName} {LastName}
        </p>
        <span>{text4}</span>
        {inputModelArray.map((inputModel, index) => {
          const inputWrapperClass =
            index === 0 ? "input-wrapper mt-20" : "input-wrapper";
          const inputName = inputNames[index];
          const inputId = inputIdFromNameMap[inputName] || inputName;
          const { name, helperText } = inputModel.fields;
          return (
            <TextInput
              inputWrapperClass={inputWrapperClass}
              inputId={inputId}
              inputName={inputName}
              inputValue={form[inputId]}
              inputOnChange={inputOnChange}
              inputOnBlur={validateForm}
              label={name}
              helperText={helperText}
              key={index}
            />
          );
        })}
      </div>
    </ShadowCard>
  );
}
