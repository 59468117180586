import { isNull } from "lodash";
import { listIdByMarketMap } from "helpers/language";
import { post } from "./request";

export const language = {
  getAll: async ({ market }) => {
    const listId = listIdByMarketMap[market];

    if (!isNull(listId)) {
      try {
        const response = await post("/api/getLanguagesList", { listId });
        return response.recordset;
      } catch {
        console.error(`Problem retrieving all languages for ${market}.`);
      }
    } else {
      console.error(
        `Problem getting listId needed for ${market} market to call getLanguagesList.`
      );
    }
  },
};
