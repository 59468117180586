export default function CountryCodePhoneInput({
  imgSrc,
  imgAlt,
  code,
  inputId,
  inputName,
  inputValue,
  inputOnChange = () => {},
  inputOnBlur = () => {},
  label = '',
  helperText = '',
}) {
  const validateInput = (e) => {
    const { value } = e.target;

    // Max character limit is 14 for phone numbers.
    if (value.length <= 14) {
      const regex = /(^$)|(^\+?[\d -]+$)/; // Regex for international phone numbers.

      if (regex.test(value)) {
        inputOnChange(e);
      }
    }
  };

  const inputProps = {
    'data-testid': inputId,
    id: inputId,
    name: inputName,
    value: inputValue,
    onChange: (e) => validateInput(e),
    onBlur: () => inputOnBlur(),
    type: 'text',
    required: true,
  };

  return (
    <div className="col-2 phone-country">
      <div className="col">
        <img src={imgSrc} alt={imgAlt} /> <span className="bold">{code}</span>
      </div>
      <div className="col">
        <div className="input-wrapper">
          <input {...inputProps} />
          <label htmlFor={inputId} aria-label={inputId}>
            {label}
          </label>
          <p className="helper-text hide">{helperText}</p>
        </div>
      </div>
    </div>
  );
}
