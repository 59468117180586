import { useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { language } from 'api/language';
import { ReactComponent as DropdownSVG } from 'assets/svg/dropdown.svg';
import { defaultLanguage, defaultCnLanguage } from 'constants/language';
import { useApp } from 'contexts/AppContext';
import { useForm } from 'contexts/FormContext';
import { TextButton } from 'components/common/buttons/TextButton';

export default function LanguageSelector() {
  // Contexts
  const {
    state: { locale },
    dispatch,
  } = useApp();
  const { market } = useForm();

  // State
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(
    locale === defaultCnLanguage.locale ? defaultCnLanguage : defaultLanguage
  );

  useEffect(() => {
    const getAllLanguages = async () => {
      const result = await language.getAll({ market });

      if (isArray(result)) {
        setLanguages(result);
      }
    };

    getAllLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market]);

  const handleChange = (e) => {
    const language = languages.find(
      (language) => language.name === e.target.innerText
    );
    setCurrentLanguage(language);
    dispatch({ type: 'updateLocale', locale: language.locale });
  };

  return (
    <ul className="mobile-wrapper">
      <li className="has-submenu sub-right">
        <TextButton title={currentLanguage.name} />
        <input type="checkbox" id="lang-menu" name="chckbx" />
        <label
          htmlFor="lang-menu"
          aria-label="Language Selection"
          aria-haspopup="true"
        >
          <DropdownSVG />
        </label>
        <ul className="sub-menu">
          {languages
            .filter((language) => language.name !== currentLanguage.name)
            .map((language, index) => (
              <li key={index} onClick={(e) => handleChange(e)}>
                <TextButton
                  datatestid={`submenu-selection_${language.name}`}
                  title={language.name}
                />
              </li>
            ))}
        </ul>
      </li>
    </ul>
  );
}
