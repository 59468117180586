import TagManager from 'react-gtm-module';
import { isEmpty } from 'lodash';
import config from 'config';

export const initGoogleTagManager = () => {
  const { REACT_APP_GTM_ID, REACT_APP_GTM_AUTH, REACT_APP_GTM_PREVIEW } = config;
  // Only initialize if env variables are present.
  if (
    !isEmpty(REACT_APP_GTM_ID) &&
    !isEmpty(REACT_APP_GTM_AUTH) &&
    !isEmpty(REACT_APP_GTM_PREVIEW)
  ) {
    const tagManagerArgs = {
      gtmId: REACT_APP_GTM_ID,
      auth: REACT_APP_GTM_AUTH,
      preview: REACT_APP_GTM_PREVIEW,
    };

    TagManager.initialize(tagManagerArgs);
  }
};
