export default function FormHeader({ data }) {
  const {
    title,
    item1,
    item5,
    item6,
    backgroundImage: {
      fields: {
        file: { url: imgSrc },
        title: imgAlt,
      },
    },
  } = data.fields;

  return (
    <section className="intro-text center map mt-20">
      <h1>{title}</h1>
      <p className="text-center wide-desktop">{item1}</p>
      {item5 && <p className="text-center wide-desktop">{item5}</p>}
      {item6 && <p className="text-center wide-desktop">{item6}</p>}
      <img src={imgSrc} alt={imgAlt} />
    </section>
  );
}
