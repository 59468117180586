import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { formApi } from 'api/form';
import { repApi } from 'api/rep';
import CnForm from 'components/cn/CnForm';
import CnBenefits from 'components/cn/CnBenefits';
import CnDetails from 'components/cn/CnDetails';
import RequestSubmitted from 'components/common/RequestSubmitted';
import FormFooter from 'components/common/footers/FormFooter';
import FormHeader from 'components/common/headers/FormHeader';
import Layout from 'components/common/layouts/Layout';
import { useApp } from 'contexts/AppContext';
import { useForm } from 'contexts/FormContext';
import { useModal } from 'contexts/ModalContext';
import { useContentful } from 'hooks/useContentful';
import Loader from "components/common/loaders/Loader";

export default function CnMigrationFormPage() {
  // Context
  const {
    state: { locale, rep },
  } = useApp();
  const { form, market } = useForm();
  const { open } = useModal();

  // Custom Hooks
  const data = useContentful('7s0uyVkDi3fUQWKSlyy2ll'); // China Form Page

  // State
  const [previousPhoneUsed, setPreviousPhoneUsed] = useState('');
  const [hasCheckedForUniqueMigration, setHasCheckedForUniqueMigration] = useState(false);
  const [success, setSuccess] = useState(false); // Tracks whether to render form complete.
  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks when the saveMigrationData call is in progress
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const isUniqueMigration = async ({ RepID }) => {
      const res = await repApi.testForUniqueMigration({ RepID });
      const { shipPhone } = res;

      if (!isEmpty(shipPhone)) {
        setPreviousPhoneUsed(shipPhone);
      } else {
        setPreviousPhoneUsed('');
      }

      setHasCheckedForUniqueMigration(true);
    };

    if (!isEmpty(rep)) {
      const { RepID } = rep;
      isUniqueMigration({ RepID });
    }
  }, [rep]);

  if (!data || !hasCheckedForUniqueMigration) {
    return null;
  }

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      setIsSubmitting(true);
      const res = await formApi.saveMigrationData({ market, locale, rep, form });
      if (typeof res === 'string') {
        if (res === 'Created new user') {
          console.log("Migration saved");
          setSuccess(true);
          return;
        } else {
          // TODO: Error handling.
        }
      }
      throw new Error(res); // Throw so we reenable the save button
    } catch (err) {
      console.error("Migration failed", err);
      setIsSubmitting(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onFormSubmit = () => {
    open({
      type: 'confirm-migration',
      props: { market: `${market}-free` },
      callback: onConfirm,
    });
  };

  const { banner1Ref, banner2Ref, banner3Ref, banner4Ref } = data.fields;

  return (
    <Layout data={banner1Ref}>
      {!success ? (
        <>
          {isLoading && <Loader active />}
          <FormHeader data={banner1Ref} />
          <CnBenefits data={banner3Ref} />
          <CnForm data={banner2Ref} previousPhoneUsed={previousPhoneUsed} />
          <CnDetails data={banner4Ref} />
          <FormFooter data={banner1Ref} onFormSubmit={onFormSubmit} isSubmitting={isSubmitting} />
        </>
      ) : (
        <RequestSubmitted />
      )}
    </Layout>
  );
}
