export const initialState = {
  BillStreet1: "",
  BillStreet2: "",
  BillCity: "",
  BillState: "",
  BillPostalCode: "",
  ShipStreet1: "",
  ShipStreet2: "",
  ShipCity: "",
  ShipState: "",
  ShipPostalCode: "",
  AddressIsTheSame: true,
  TermsAndConditions: false,
  IsValid: false,
};

export const initialStateCn = {
  BillPhone: "",
  ConfirmBillPhone: "",
  PhoneIsUnique: false,
  TermsAndConditions: false,
  IsValid: false,
};

export const initialStateHkcb = {
  FirstName: "",
  LastName: "",
  Company: "",
  Email: "",
  Password: "",
  BillStreet1: "",
  BillStreet2: "",
  BillCity: "",
  BillState: "",
  BillPostalCode: "",
  ShipStreet1: "",
  ShipStreet2: "",
  ShipCounty: "",
  ShipCity: "",
  ShipState: "",
  AddressIsTheSame: false,
  TermsAndConditions: false,
  IsValid: false,
};

export const initialStateJp = {
  RomanizedFirstName: "",
  RomanizedLastName: "",
  BillStreet1: "",
  BillStreet2: "",
  BillCity: "",
  BillState: "",
  BillPostalCode: "",
  BillPhone: "",
  ShipStreet1: "",
  ShipStreet2: "",
  ShipCity: "",
  ShipState: "",
  ShipPostalCode: "",
  ShipPhone: "",
  AddressIsTheSame: true,
  TermsAndConditions: false,
  IsValid: false,
};

export const initialStateKr = {
  FirstName: "",
  LastName: "",
  BankName: "",
  BankAccountNumber: "",
  BankAccountName: "",
  DateOfBirth: "",
  BillStreet1: "",
  BillStreet2: "",
  BillCity: "",
  BillState: "",
  BillPostalCode: "",
  BillPhone: "",
  ShipStreet1: "",
  ShipStreet2: "",
  ShipCity: "",
  ShipState: "",
  ShipPostalCode: "",
  ShipPhone: "",
  AddressIsTheSame: true,
  TermsAndConditions: false,
  IsValid: false,
  ResidentRegistrationNumber: "",
  Market: "",
  CompanyName: "",
  Phone: "",
  DefaultLocaleID: "",
  Address1: "",
  Address2: "",
  City: "",
  State: "",
  PostalCode: "",
  Country: "",
  County: "",
  ShipAddress1: "",
  ShipAddress2: "",
  ShipCountry: "",
  ShipCounty: "",
  RomanizedFirstName: "",
  RomanizedLastName: "",
  Email: "",
  RepDID: "",
  SponsorRepDID: "",
  JoinDate: "",
  URL: "",
  UplineRepDID: "",
  AddressValidated: "",
  ShipAddressValidated: "",
  PreferredCulture: "",
  Locale: "",
  GovernmentID: "",
};
