import { useEffect, useState } from "react";
import { addressApi } from "api/address";
import { formApi } from "api/form";
import RequestSubmitted from "components/common/RequestSubmitted";
import FlagHeader from "components/common/headers/FlagHeader";
import FormFooter from "components/common/footers/FormFooter";
import Layout from "components/common/layouts/Layout";
import HkcbForm from "components/hkcb/HkcbForm";
import { useApp } from "contexts/AppContext";
import { useForm } from "contexts/FormContext";
import { useModal } from "contexts/ModalContext";
import { isSoValid, parseAddressFormFieldsFromSo } from "helpers/so";
import { useContentful } from "hooks/useContentful";
import Loader from "components/common/loaders/Loader";
import config from "../../../config";

export default function HkcbMigrationFormPage(props) {
  const {
    config: {
      contentful: { form: contentfulId },
    },
  } = props;
  // Context
  const {
    state: { locale, rep },
  } = useApp();
  const { form, market, overwriteAddress } = useForm();
  const { open } = useModal();

  // Custom Hooks
  const pageData = useContentful(contentfulId);

  // State
  const [success, setSuccess] = useState(false); // Tracks whether to render form complete.
  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks when the saveMigrationData call is in progress
  const [scrollCount, setScrollCount] = useState(0);
  const [navUrl, set_navUrl] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      saveMigrationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  if (!pageData) {
    return null;
  }
  const saveMigrationData = async () => {
    try {
      setIsLoading(true);
      const res = await formApi.saveMigrationData({
        market,
        locale,
        rep,
        form,
      });
      const { url, message } = res;
      if (url) {
        console.log("Migration saved");
        setSuccess(true);
        set_navUrl(url);
        return;
      } else if (message === "Contains illegal characters") {
        open({ type: "illegal-characters" });
      } else {
        // TODO: Error handling.
      }
      throw new Error(message); // Throw so we reenable the save button
    } catch (err) {
      console.error("Migration failed", err);
      setIsSubmitting(false);
    } finally {
      setIsLoading(false);
    }
  };

  const validateAddress = async ({ addressType, callback = () => {} }) => {
    setIsLoading(true);
    // Validate address against service objects.
    const country = addressType === "Ship" ? "China" : "Hong Kong";
    const so = await addressApi.validate({ addressType, form, country });
    setIsLoading(false);
    const parsedAddressForChina = parseAddress(so);
    if (isSoValid({ so: parsedAddressForChina })) {
      open({
        type: "confirm-address",
        props: {
          addressType,
          form,
          so,
        },
        callback,
      });
    } else {
      open({
        type: "invalid-address",
        props: {
          addressType,
          form,
          cancelCallback: () => {
            window.location.hash = "#address-section";
            setScrollCount(scrollCount + 1);
          },
        },
        callback,
      });
    }
  };

  const validateBillingAddress = () => {
    let address = {};
    validateAddress({
      addressType: "Bill",
      callback: ({ overwrite, so }) => {
        if (overwrite) {
          address = {
            ...address,
            ...parseAddressFormFieldsFromSo({ addressType: "Bill", so }),
          };
        }
        overwriteAddress({ address });
        setIsSubmitting(true);
      },
    });
  };

  const onFormSubmit = () => {
    open({
      type: "confirm-migration",
      props: { market, Email: form.Email },
      callback: validateBillingAddress,
    });
  };

  const { banner1Ref } = pageData.fields;
  banner1Ref.overrideCancelButtonUrl = config.REACT_APP_CHINA_TOOLS;
  return (
    <Layout data={banner1Ref}>
      {!success ? (
        <>
          {isLoading && <Loader active />}
          <FlagHeader data={banner1Ref} market={market} />
          <HkcbForm data={pageData} config={props.config} />
          <FormFooter data={banner1Ref} onFormSubmit={onFormSubmit} isSubmitting={isSubmitting} />
        </>
      ) : (
        <RequestSubmitted market={market} navUrl={navUrl} />
      )}
    </Layout>
  );
}

function parseAddress(so) {
  const { Address1, Address2, Locality, AdministrativeArea } =
    so?.AddressInfoResponse?.AddressInfo;
  const newAddress = {
    ...so?.AddressInfoResponse?.AddressInfo,
    Address1: Address2,
    Locality,
    AdministrativeArea,
    PostalCode: Address1,
  };
  so.AddressInfoResponse.AddressInfo = newAddress;
  return so;
}
