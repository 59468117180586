import { validStatuses, validResolutionLevels } from "constants/so";

// Validates SO response.
export const isSoValid = ({ so }) => {
  const { AddressInfo: { Status, ResolutionLevel } = {} } = so;
  return (
    so &&
    validStatuses.includes(Status) &&
    validResolutionLevels.includes(ResolutionLevel)
  );
};

export const parseAddressFormFieldsFromSo = ({ addressType, so }) => {
  const { Address1, Address2, Locality, AdministrativeArea, PostalCode } =
    so?.AddressInfoResponse?.AddressInfo;

  const address = {};

  Address1 && (address[`${addressType}Street1`] = Address1);
  Address2 &&
    typeof Address2 === "string" &&
    typeof Locality === "string" &&
    !Address2.includes(Locality) &&
    (address[`${addressType}Street2`] = Address2);
  Locality && (address[`${addressType}City`] = Locality);
  AdministrativeArea && (address[`${addressType}State`] = AdministrativeArea);
  PostalCode && (address[`${addressType}PostalCode`] = PostalCode);

  return address;
};

export const parseServiceObjectAutoComplete = ({ addressType, so }) => {
  const { Address1, Address2, Locality, AdminArea, PostalCode } = so;
  switch (addressType) {
    case "Bill":
      return {
        BillStreet1: Address1,
        BillStreet2: !Address2.includes(Locality) ? Address2 : "",
        BillCity: Locality,
        BillState: AdminArea,
        BillPostalCode: PostalCode,
      };
    case "Ship":
      return {
        ShipStreet1: Address1,
        ShipStreet2: !Address2.includes(Locality) ? Address2 : "",
        ShipCity: Locality,
        ShipState: AdminArea,
        ShipPostalCode: PostalCode,
      };
    default:
      throw new Error(
        `Unhandled address type in parseServiceObjectAddressPayload: ${addressType}`
      );
  }
};

export const parseIsoFromMarket = ({ market }) => {
  switch (market) {
    case "gb":
      return "GBR";
    default:
      return typeof market === "string" ? market.toUpperCase() : market;
  }
};
