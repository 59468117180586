import SiteHeader from "components/common/headers/SiteHeader";
import {
  Box,
} from '@newulife/common'

export default function Layout({
  enableLanguageSelector = true,
  data,
  market,
  children,
}) {
  const { image, image1 } = data?.fields;
  return (
    <Box
      className="background-wrapper profile"
      sx={{
        backgroundImage: `url(${
          image ? image?.fields?.file?.url : image1?.fields?.file?.url
        })`,

      }}
    >    
      <SiteHeader enableLanguageSelector={enableLanguageSelector} />
      <div className='page create-account'>
        <div className='grid'>
          <div className={`main-content ${market}`}>{children}</div>
        </div>
      </div>
    </Box>
  );
}
