import { useEffect } from 'react';
import ShadowCard from 'components/common/cards/ShadowCard';
import BlueSwitchCheckboxInput from 'components/common/inputs/BlueSwitchCheckboxInput';
import TextInputForm from 'components/common/forms/TextInputForm';
import { useForm } from 'contexts/FormContext';

export default function JpFormShippingAddressSection({ data }) {
  // Context
  const { form, inputOnChange, validateForm } = useForm();

  // Effects
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.AddressIsTheSame]);

  // Contentful
  const { title, text1, text2, text3, inputModelArray } = data.fields;

  // inputNames need to match the order of models in inputModelArray.
  const inputNames = [
    'shipping-address-line-1',
    'shipping-address-line-2',
    'shipping-address-city',
    'shipping-address-state',
    'shipping-address-postal',
    'shipping-address-phone',
  ];

  return (
    <ShadowCard label={title}>
      <div className="form-max flex-20 column" id="address-section">
        <p className="address-country">
          {text1}
          <strong>{text2}</strong>
        </p>
        <TextInputForm
          form={form}
          validateForm={validateForm}
          inputs={inputModelArray}
          inputNames={inputNames}
          inputOnChange={inputOnChange}
        />
        <BlueSwitchCheckboxInput
          inputId="AddressIsTheSame"
          inputName="AddressIsTheSame"
          inputAriaLabel="AddressIsTheSame"
          inputChecked={form.AddressIsTheSame}
          inputOnChange={inputOnChange}
          label={text3}
        />
      </div>
    </ShadowCard>
  );
}
