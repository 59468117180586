import { emailRegex } from './regex'

export const config = {
  flags: [],
  markets: [
    {
      route: "jp",
      options: { visible: true }
    },
    {
      route: "sg",
      options: { visible: true }
    },
    {
      route: "be",
      options: { visible: true }
    },
    {
      route: "cn",
      options: { visible: true }
    },
    {
      route: "gb",
      options: { visible: true }
    },
    {
      route: "de",
      options: { visible: true }
    },
    {
      route: "kr",
      options: { visible: true }
    },
    {
      route: "my",
      options: { visible: true }
    },
    {
      route: "nl",
      options: { visible: true }
    },
    {
      route: "hkcb",
      market: "Hong Kong Cross Market",
      country: "Hong Kong",
      shipCountry: "China",
      options: { visible: true },
      contentful: {
        form: "47GHxziJCF8lY2ewqYQoeg",
        confirmModalId: "3k1oBoGR46ZJgn8gjUdc98"
      },
      sections: {
        personal: ["fname", "lname", "company", "email", "password"]
      },
      inputs: {
        password: {
          type: "password"
        },
        email: {
          type: "email",
          onBlurPattern: emailRegex
        }
      }
    },
    {
      route: "test",
      market: "Test Route",
      options: { visible: true }
    }
  ]
}
