import { useState } from "react";
import InputMask from "react-input-mask";
import { autoCompleteAttrFromNameMap } from "helpers/form";
import { isEmpty } from "lodash";

/**
 * Used for forms.
 *
 * @param {inputWrapperClass} string wrapper class(es).
 * @param {inputId} string input identifier.
 * @param {inputName} string input name.
 * @param {inputPattern} regex input pattern regular expression.
 * @param {inputValue} string input value.
 * @param {inputOnChange} function callback function triggered on change.
 * @param {inputOnBlur} function callback function triggered on blur.
 * @param {label} string label text.
 * @param {helperText} string helper text.
 * @returns a text input.
 */

const MASKS = {
  socialSecurityNumber: "999-99-9999",
  ein: "99-999999",
  internationalPhoneNumber: "+99 (999) 999-9999",
  date: "99/99/9999",
  inverseDate: "9999/99/99",
  zipCode: "99999",
  creditCard: "9999 9999 9999 9999",
  creditCardExpiry: "99/99",
  creditCardCVC: "999",
  residentRegistrationNumber: "999999-9999999",
};

const errorStyle = {
  display: "inline-flex",
  fontFamily: "Waleray,sans-serif",
  fontStyle: "normal",
  fontSize: "14px",
  color: "#9a3b26",
  fontWeight: "600",
};

export default function TextInput({
  inputWrapperClass,
  inputId = "",
  inputName = "",
  inputPattern = "",
  onBlurPattern = "",
  inputValue = "",
  inputOnChange = () => {},
  inputOnBlur,
  label = "",
  helperText = "",
  showHelperText = false,
  dynamicHelperText = false,
  inputErrorText = "",
  required,
  mask,
  maxLength,
  type,
}) {
  // Component state
  const [hasError, setHasError] = useState(false);

  // Handler Functions
  const onChange = (e) => {
    if (typeof inputPattern === "object") {
      if (inputPattern.test(e.target.value)) {
        inputOnChange(e);
      }
    } else {
      inputOnChange(e);
    }
  };

  const handleBlur = () => {
    if (
      required &&
      (!inputValue ||
        (typeof onBlurPattern === "object" && !onBlurPattern.test(inputValue)))
    ) {
      // Is requied, has onBlurPattern, doesn't match pattern
      setHasError(true);
    } else if (
      !required &&
      !isEmpty(inputValue) &&
      typeof onBlurPattern === "object" &&
      !onBlurPattern.test(inputValue)
    ) {
      // Is NOT requied, has onBlurPattern, doesn't match pattern
      setHasError(true);
    } else {
      // all other cases
      setHasError(false);
    }
    inputOnBlur();
  };

  // Input Attributes
  const inputProps = {
    "data-testid": inputId,
    id: inputId,
    name: inputName,
    value: inputValue,
    onChange: onChange,
    onBlur: handleBlur,
    onFocus: () => setHasError(false),
    type,
    maxLength,
  };
  const inputAutoComplete = autoCompleteAttrFromNameMap[inputName] || false;
  if (inputAutoComplete) {
    inputProps.autoComplete = inputAutoComplete;
  }

  // Render functions
  const getClassNames = () => {
    return `input-wrapper${hasError ? " error" : ""}${
      inputWrapperClass ? ` ${inputWrapperClass}` : ""
    }`;
  };
  const renderInput = () => {
    return mask ? (
      <InputMask mask={MASKS[mask]} {...inputProps} />
    ) : (
      <input {...inputProps} />
    );
  };
  const renderSubText = () => {
    if (!hasError) {
      return (
        <p
          className={`helper-text${showHelperText ? "" : " hide"}${
            dynamicHelperText ? " reveal" : ""
          }`}
        >
          {helperText}
        </p>
      );
    } else {
      return (
        <p
          className='helper-text error'
          style={errorStyle}
          data-testid={`${inputId}-required`}
        >
          {inputErrorText}
        </p>
      );
    }
  };

  return (
    <div className={getClassNames(hasError, inputWrapperClass)} key={inputId}>
      {renderInput()}
      <label htmlFor={inputId} aria-label={inputId}>
        {label}
      </label>
      {renderSubText()}
    </div>
  );
}
