import Modal from './Modal';
import { LoaderRing } from '../loaders/Loader';

export default function LoadingModal() {
  return (
    <Modal id="loading-modal" cancelType>
      <div className="loading-modal">
        <LoaderRing active />
      </div>
    </Modal>
  );
}
