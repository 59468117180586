import { useEffect, useState } from "react";
import { addressApi } from "api/address";
import { bankApi } from "api/bank";
import DistributorInfoSection from "components/common/sections/DistributorInfoSection";
import { useForm } from "contexts/FormContext";
import KrFormShippingAddressSection from "./KrFormShippingAddressSection";
import KrFormBillingAddressSection from "./KrFormBillingAddressSection";
import KrFormPersonalInformationSection from "./KrFormPersonalInformationSection";
import KrFormBankInformationSection from "./KrFormBankInformationSection";

export default function KrForm({ data, market }) {
  // Context
  const { form } = useForm();

  // State
  const [banks, setBanks] = useState([]);
  const [states, setStates] = useState([]);

  // Effects
  useEffect(() => {
    const getKoreanBanks = async () => {
      const res = await bankApi.getKoreanBanks();

      if (res.length !== 0) {
        // Parse banks to be rendered by select dropdown input.
        const options = res.map((r) => ({
          id: r.BankNameKorean,
          text: r.BankNameKorean,
        }));
        setBanks(options);
      }
    };
    getKoreanBanks();
  }, []);

  // Effects
  useEffect(() => {
    const getKoreanStates = async () => {
      const res = await addressApi.getCountryStates(market);

      if (res.length !== 0) {
        // Parse states to be rendered by select dropdown input.
        const options = res.map((r) => ({
          id: r.Title,
          text: r.Title,
        }));
        setStates(options);
      }
    };
    getKoreanStates();
  }, [market]);

  // Contentful
  const { reference2, reference3, reference4, reference5, reference6 } =
    data.fields;

  return (
    <>
      <DistributorInfoSection data={reference2} market={market} />
      <KrFormPersonalInformationSection data={reference3} />
      <KrFormBankInformationSection banks={banks} data={reference4} />
      <KrFormShippingAddressSection states={states} data={reference6} />
      {!form.AddressIsTheSame && (
        <KrFormBillingAddressSection states={states} data={reference5} />
      )}
    </>
  );
}
