import ShadowCard from 'components/common/cards/ShadowCard';
import { useApp } from 'contexts/AppContext';

export default function SgFormDistributorInfoSection({ data }) {
  // Context
  const {
    state: { rep },
  } = useApp();

  // Rep
  const { RepDID, Email, FirstName, LastName, Phone1 } = rep;

  // Contentful
  const { text1, text2, text3, text4 } = data.fields;

  return (
    <ShadowCard>
      <div className="form-max flex-20 column">
        <p className="text-center wide-desktop">
          <span className="bold">{text1}</span>
          <br />
          {RepDID}
        </p>
        <p className="text-center wide-desktop">
          <span className="bold">{text2}</span>
          <br />
          {FirstName} {LastName}
        </p>
        <p className="text-center wide-desktop">
          <span className="bold">{text3}</span>
          <br />
          {Email}
        </p>
        <p className="text-center wide-desktop">
          <span className="bold">{text4}</span>
          <br />
          {Phone1}
        </p>
      </div>
    </ShadowCard>
  );
}
