import { createContext, useContext, useState } from "react";
import CancelModal from "components/common/modals/CancelModal";
import ConfirmMigrationModal from "components/common/modals/ConfirmMigrationModal";
import IllegalCharactersModal from "components/common/modals/IllegalCharactersModal";
import InvalidAddressModal from "components/common/modals/InvalidAddressModal";
import { initialState } from "constants/modal";
import ConfirmAddressModal from "components/common/modals/ConfirmAddressModal";
import BankErrorModal from "components/common/modals/BankErrorModal";
import LoadingModal from "components/common/modals/LoadingModal";

const ModalContext = createContext();

const ModalRenderer = ({ type, props, callback, close }) => {
  switch (type) {
    case "cancel":
      return <CancelModal {...props} callback={callback} close={close} />;
    case "confirm-address":
      return (
        <ConfirmAddressModal {...props} callback={callback} close={close} />
      );
    case "confirm-migration":
      return (
        <ConfirmMigrationModal {...props} callback={callback} close={close} />
      );
    case "illegal-characters":
      return (
        <IllegalCharactersModal {...props} callback={callback} close={close} />
      );
    case "invalid-address":
      return (
        <InvalidAddressModal {...props} callback={callback} close={close} />
      );
    case "bank-error":
      return <BankErrorModal {...props} callback={callback} close={close} />;
    case "loading-modal":
      return <LoadingModal />;
    default:
      console.error(`Unhandled modal type in ModalRenderer: ${type}`);
  }
};

// Used to manage modal types and the opened/closed status of them.
const ModalContextProvider = ({ children }) => {
  const [modal, setModal] = useState(initialState);

  const open = ({ type = "", props = {}, callback = () => {} }) => {
    // Scroll parent to the top.
    const parentOrigin = "https://dash.newulife.com/";
    window.parent.postMessage({ task: "scrollToTop" }, parentOrigin);

    // Scroll to top.
    window.scrollTo(0, 0);

    // Prevent scrolling on body.
    document.body.classList.add("modal-open");

    setModal({ type, props, callback, open: true });
  };

  const close = () => {
    // Remove scroll lock on body.
    document.body.classList.remove("modal-open");

    setModal(initialState);
  };

  return (
    <ModalContext.Provider value={{ open, close }}>
      {modal.open && (
        <ModalRenderer
          type={modal.type}
          props={modal.props}
          callback={modal.callback}
          close={close}
        />
      )}
      {children}
    </ModalContext.Provider>
  );
};

// Custom hook to consume context.
const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useModal must be used within a ModalContextProvider");
  }

  return context;
};

export { ModalContextProvider, useModal };
