import { Routes, Route } from 'react-router-dom';
import CnMigrationRequestPage from './index';
import CnMigrationRequestConfirmationPage from './confirmation';

export default function CnMigrationRequestRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CnMigrationRequestPage />} />
      <Route
        path="confirmation"
        element={<CnMigrationRequestConfirmationPage />}
      />
    </Routes>
  );
}
