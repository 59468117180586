import { useEffect, useState } from 'react';
import { isEmpty, isNull } from 'lodash';
import { guidApi } from 'api/guid';
import Loader from 'components/common/loaders/Loader';
import { useApp } from 'contexts/AppContext';
import { useQueryParams } from 'hooks/useQueryParams';
import config from 'config';

export default function GuidAuth({ children }) {
  // State
  const [hasDispatched, setHasDispatched] = useState(false);
  const [hasValidated, setHasValidated] = useState(false);

  // Context
  const {
    state: { rep },
    dispatch,
  } = useApp();
  const { params } = useQueryParams();

  const id = params.get('guid');

  useEffect(() => {
    if (!isNull(id)) {
      const validateGuid = async () => {
        const res = await guidApi.validate({ guid: id });

        if (typeof res === 'object' && !res.ValidationErrors) {
          dispatch({ type: 'updateRep', rep: { ...res } });
          setHasDispatched(true);
        }

        setHasValidated(true);
      };

      validateGuid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Development Mode.
  if (isNull(id) && config.NODE_ENV === 'development') {
    return children;
  }

  // Return nothing until guid is validated.
  if (!hasValidated && isEmpty(rep)) {
    return <Loader active />;
  }

  // Return children if guid has been validated and returned rep information.
  if (hasValidated && hasDispatched) {
    if (isNull(rep.RepDID)) {
      return <p>RepDID was not found.</p>;
    } else {
      return children;
    }
  }

  // Return error message if guid has been validated but errored out.
  if (hasValidated && !hasDispatched) {
    return <p>Something went wrong.</p>;
  }

  return null;
}
