import { createContext, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { getInitialStateByMarket } from 'helpers/form';
import { isFormValid } from 'helpers/validation';

const FormContext = createContext();

const inputOnChangeFilters = {
  'default': /[()+_]/g,
  'Email': /[()]/g,
  'BillStreet1': "",
  'BillStreet2': "",
  'ShipStreet1': "",
  'ShipStreet2': ""
};

// Used to manage state for multiple sections for form submissions.
const FormContextProvider = ({ market, children }) => {
  const [form, setForm] = useState(getInitialStateByMarket(market));

  // Handles single input field changes.
  const inputOnChange = (e) => {
    const { type, id, checked, value } = e.target;
    
    if (type === 'checkbox') {
      setForm({ ...form, [id]: checked });
    } else {
      // Invalidate when text field is empty.
      const filter = inputOnChangeFilters[id] ?? inputOnChangeFilters['default'];
      if (isEmpty(value)) {
        setForm({
          ...form,
          [id]: value.replace(filter, ''),
          IsValid: false,
        });
      } else {
        setForm({ ...form, [id]: value.replace(filter, '') });
      }
    }
  };

  // Handles overriding address fields with a service object address.
  const overwriteAddress = ({ address }) => {
    setForm({ ...form, ...address });
  };

  // Handles validating the required fields on the form.
  const validateForm = () => {
    const isValid = isFormValid({ market, form });
    if (isValid) {
      setForm({ ...form, IsValid: true });
    } else {
      setForm({ ...form, IsValid: false });
    }
  };

  // Handles validating the required fields on the form alongside the status of whether a number is unique.
  const validateFormWithUniquePhoneNumber = ({ PhoneIsUnique }) => {
    const currentForm = { ...form, PhoneIsUnique };
    const isValid = isFormValid({ market, form: currentForm });

    if (isValid) {
      setForm({ ...form, PhoneIsUnique, IsValid: true });
    } else {
      setForm({ ...form, PhoneIsUnique, IsValid: false });
    }
  };

  return (
    <FormContext.Provider
      value={{
        form,
        setForm,
        market,
        inputOnChange,
        overwriteAddress,
        validateForm,
        validateFormWithUniquePhoneNumber,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

// Custom hook to consume context.
const useForm = () => {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error('useForm must be used within a FormContextProvider');
  }

  return context;
};

export { FormContextProvider, useForm };
