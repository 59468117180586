import dayjs from 'dayjs';
import ShadowCard from 'components/common/cards/ShadowCard';
import { useApp } from 'contexts/AppContext';
import config from 'config';

export default function DistributorInfoSection({ data, market }) {
  // Context
  const {
    state: { rep },
  } = useApp();

  const imageURL = config.REACT_APP_BYDESIGN_IMAGE_HOST_URL;

  // Rep
  const {
    RepDID,
    Email,
    FirstName,
    LastName,
    Phone1,
    PublicImageUrl,
    DateofBirth,
  } = rep;

  // Contentful
  const { title, text1, text2, text3, text4, text5 } = data.fields;

  return (
    <ShadowCard label={title} hasAvatar={market === 'kr' && !!PublicImageUrl}>
      <div className='form-max flex-20 column center'>
        {market === 'kr' && PublicImageUrl && (
          <img
            alt={`${FirstName} ${LastName}`}
            className='kr-migration-profile'
            src={`${imageURL}/${PublicImageUrl}`}
          />
        )}
        <p className='text-center wide-desktop'>
          <span className='bold'>{text1}</span>
          <br />
          {RepDID}
        </p>
        <p className='text-center wide-desktop'>
          <span className='bold'>{text2}</span>
          <br />
          {FirstName} {LastName}
        </p>
        {market === 'kr' &&
          DateofBirth &&
          dayjs(DateofBirth).format('YYYY/MM/DD') !== '1900/01/01' && (
            <p className='text-center wide-desktop'>
              <span className='bold'>{text5}</span>
              <br />
              {dayjs(DateofBirth).format('YYYY/MM/DD')}
            </p>
          )}
        <p className='text-center wide-desktop'>
          <span className='bold'>{text3}</span>
          <br />
          {Email}
        </p>
        <p className='text-center wide-desktop'>
          <span className='bold'>{text4}</span>
          <br />
          {Phone1}
        </p>
      </div>
    </ShadowCard>
  );
}
