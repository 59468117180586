import { Routes, Route } from 'react-router-dom';
import { FormContextProvider } from 'contexts/FormContext';
import BeMigrationFormPage from './migration-form';

export default function BeMigrationRoutes() {
  return (
    <FormContextProvider market="be">
      <Routes>
        <Route path="migration-form" element={<BeMigrationFormPage />} />
      </Routes>
    </FormContextProvider>
  );
}
