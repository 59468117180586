/**
 * Used to encapsulate content.
 *
 * @param {label} string renders a label surrounding the card.
 * @returns a card container.
 */

export default function ShadowCard({ label, hasAvatar = false, children }) {
  return (
    <section className="card--container">
      {label && (
        <p className={`card-label${hasAvatar && " card-label--with-avatar"}`}>
          {label}
        </p>
      )}
      <div className="card shadow">
        <div className="inner-content">{children}</div>
      </div>
    </section>
  );
}
