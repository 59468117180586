import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import ShadowCard from 'components/common/cards/ShadowCard';
import TextInputForm from 'components/common/forms/TextInputForm';
import { useForm } from 'contexts/FormContext';
import { parseIsoFromMarket, parseServiceObjectAutoComplete } from 'helpers/so';
import { useServiceObjects } from 'hooks/useServiceObjects';

export default function EuFormBillingAddressSection({ data }) {
  // Context
  const { form, market, inputOnChange, overwriteAddress, validateForm } =
    useForm();

  // Constants

  // inputNames need to match the order of models in inputModelArray.
  const inputNames = [
    'billing-address-line-1',
    'billing-address-line-2',
    'billing-address-city',
    'billing-address-postal',
    'billing-address-state',
  ];

  // Get Service Object ISO.
  const iso = parseIsoFromMarket({ market });

  // Custom Hooks
  const { so } = useServiceObjects({
    inputNames,
    iso,
  });

  // Effects
  useEffect(() => {
    if (!isEmpty(so)) {
      onFormAutoComplete({ so });
    }
  }, [so]);

  // Handles Service Object Auto Complete
  const onFormAutoComplete = ({ so }) => {
    // Parse auto complete return.
    const addressType = 'Bill';
    const address = parseServiceObjectAutoComplete({
      addressType,
      so,
    });

    // Overwrite form.
    overwriteAddress({ address });
  };

  // Contentful
  const {
    title,
    text1,
    text2,
    reference1: {
      fields: { listArray: inputModelArray },
    },
  } = data.fields;

  return (
    <ShadowCard label={title}>
      <div className="form-max flex-20 column">
        <p className="address-country">
          {text1}
          <strong>{text2}</strong>
        </p>
        <TextInputForm
          form={form}
          validateForm={validateForm}
          inputs={inputModelArray}
          inputNames={inputNames}
          inputOnChange={inputOnChange}
        />
      </div>
    </ShadowCard>
  );
}
