export default function SoAddressText({ so }) {
  const {
    Address1,
    Address2,
    Locality,
    AdministrativeArea,
    PostalCode,
    CountryISO2,
    Country,
  } = so?.AddressInfoResponse?.AddressInfo;
  if (CountryISO2 === "CN") {
    return (
      <p className='address'>
        {PostalCode && (
          <>
            {PostalCode}
            <br />
          </>
        )}
        {Address1 && (
          <>
            {Address1}
            <br />
          </>
        )}
        {/* {Address2 &&
        typeof Address2 === 'string' &&
        typeof Locality === 'string' &&
        !Address2.includes(Locality) && (
          <>
            {Address2}
            <br />
          </>
        )} */}
        {Locality && (
          <>
            {Locality}
            <br />
          </>
        )}
        {AdministrativeArea && (
          <>
            {AdministrativeArea}
            <br />
          </>
        )}
        {/* {Country && Country} */}
      </p>
    );
  }
  return (
    <p className='address'>
      {Address1 && (
        <>
          {Address1}
          <br />
        </>
      )}
      {Address2 &&
        typeof Address2 === "string" &&
        typeof Locality === "string" &&
        !Address2.includes(Locality) && (
          <>
            {Address2}
            <br />
          </>
        )}
      {Locality && (
        <>
          {Locality}
          <br />
        </>
      )}
      {AdministrativeArea && (
        <>
          {AdministrativeArea}
          <br />
        </>
      )}
      {PostalCode && PostalCode}
    </p>
  );
}
