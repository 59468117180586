import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { inputIdFromNameMap } from "helpers/form";
import config from 'config';

// See public/index.html for additional css/js files necessary for this to work.
export const useServiceObjects = ({ inputNames, iso }) => {
  // Ref
  const DOTSGlobalAddressComplete = useRef({});

  // State
  const [so, setSo] = useState({});

  // Isos that are able to use service objects autocomplete.
  const isos = ["DE", "BE", "GBR", "NL"];

  // Effects
  useEffect(() => {
    // If an autocomplete listener already exists, destroy it.
    if (!isEmpty(DOTSGlobalAddressComplete.current)) {
      DOTSGlobalAddressComplete.current.destroy();
    }

    // Create an autocomplete listener.
    if (isos.includes(iso)) {
      window.options = {
        key: config.REACT_APP_SERVICE_OBJECTS_KEY,
        setBiasToIP: true,
        countries: { codesList: iso },
        bar: { showCountry: true },
        isTrial: false,
      };

      try {
        const { DEFAULT: mode } = window?.so?.fieldMode;
        const fields = inputNames.map((inputName) => {
          const element = inputIdFromNameMap[inputName] || inputName;
          const field = serviceObjectFieldFromNameMap[inputName] || "";
          return {
            element,
            field,
            mode,
          };
        });

        DOTSGlobalAddressComplete.current = new window.so.Address(
          fields,
          window.options
        );

        DOTSGlobalAddressComplete.current.listen(
          "results",
          (addressObj, { Debug, Error }) => {
            if (Error) console.error({ ip: Debug, message: Error });
          }
        );

        DOTSGlobalAddressComplete.current.listen(
          "populate",
          (address, variations) => {
            setSo(address);
          }
        );
      } catch {
        console.error("Failed to create Service Objects autocomplete service.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iso]);

  return {
    so,
  };
};

const serviceObjectFieldFromNameMap = {
  "address-line-1": "Address1",
  "address-line-2": "Address2",
  "address-city": "Locality",
  "address-state": "AdministrativeArea",
  "address-postal": "PostalCode",
  "shipping-address-line-1": "Address1",
  "shipping-address-line-2": "Address2",
  "shipping-address-city": "Locality",
  "shipping-address-state": "AdministrativeArea",
  "shipping-address-postal": "PostalCode",
};
