import { useApp } from "contexts/AppContext";
import { useContentful } from "hooks/useContentful";
import Modal from "./Modal";
import { confirmModalIdByMarketMap } from "helpers/contentful";

export default function ConfirmMigrationModal({
  market,
  callback,
  close,
  Email,
}) {
  // Contexts
  const {
    state: { rep },
  } = useApp();

  // Custom Hooks
  const contentfulId = confirmModalIdByMarketMap[market];
  if (!contentfulId)
    console.warn(
      'The confirm address modal requires a market and id in "/src/helpers/contentful.js"'
    );
  const data = useContentful(contentfulId); // Confirm Modal Info

  if (!data) {
    return null;
  }

  const onConfirm = () => {
    close();
    callback();
  };

  const { RepDID } = rep;
  const { title, item1, item2, item3, buttonText } = data.fields;

  return (
    <Modal id='confirm-migration-modal' close={close}>
      <section className='intro-text center'>
        <h2 className='mt-20'>{title}</h2>
        <p className='text-center wide-desktop'>{item1}</p>
      </section>
      <div className='col center'>
        <p className='text-center wide-desktop'>
          <span className='bold'>{item2}</span>
          <br data-testid={`${item2?.replace(/\W/g, "").toLowerCase()}`} />
          {RepDID}
        </p>
        <p className='text-center wide-desktop'>
          <span className='bold'>{item3}</span>
          <br data-testid={`${item3?.replace(/\W/g, "").toLowerCase()}`} />
          {Email}
        </p>
      </div>
      <div className='mt-20 center'>
        <button
          data-testid={`${title?.replace(/\W/g, "").toLowerCase()}_btn`}
          className='button'
          onClick={() => onConfirm()}
        >
          {buttonText}
        </button>
      </div>
    </Modal>
  );
}
