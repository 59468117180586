import ShadowCard from 'components/common/cards/ShadowCard';
import ValidCompleteIcon from 'components/common/icons/ValidCompleteIcon';

export default function CnBenefits({ data }) {
  const { title, item1, item2, item3, item4, item5, item6, item7 } =
    data.fields;

  // List of text displayed.
  const itemList = [item1, item2, item3, item4, item5, item6, item7];

  return (
    <ShadowCard>
      <div className="center">
        <p className="text-center wide-desktop">
          <span className="bold">{title}</span>
        </p>
        <ul className="validation-list active">
          {itemList.map((item, index) => (
            <li key={index}>
              <ValidCompleteIcon />
              <p className="text-left wide-desktop">{item}</p>
            </li>
          ))}
        </ul>
      </div>
    </ShadowCard>
  );
}
