import { createClient } from 'contentful';
import config from 'config';

const client = createClient({
  space: config.REACT_APP_CONTENTFUL_SPACE,
  accessToken: config.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const customError = (code = 500, message = 'Unknown Error') => {
  return {
    code,
    error: `Error retrieving Contentful data- ${message}`,
  };
};

export const getEntryByLocale = (id, locale) => {
  return new Promise((resolve, reject) => {
    client
      .getEntry(id, { locale: locale, include: 10 })
      .then((entry) => {
        resolve(entry);
      })
      .catch((err) => {
        console.error(
          `Contentful was unable to return requested content - entry id by locale - ${id}\n${err}`
        );
        resolve(
          customError(
            501,
            `Contentful was unable to return requested content - entry id by locale - ${id}`
          )
        );
      });
  });
};
