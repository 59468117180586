import { chineseCharactersDigitsSymbols, chineseCharactersDigitsSymbolsMax5English, fiveDigitsRegex } from "constants/regex";
import { isEmpty } from "lodash";

export const isFormValid = ({ market, form }) => {
  switch (market) {
    case "cn":
      return isCnFormValid({ form });
    case "jp":
      return isJpFormValid({ form });
    case "kr":
      return isKrFormValid({ form });
    case "sg":
      return isSgFormValid({ form });
    case "nl":
      return isNlFormValid({ form });
    case "hkcb":
      return isHkcbFormValid({ form });
    default:
      return isDefaultFormValid({ form });
  }
};

// Default
const isDefaultFormValid = ({ form }) => {
  const {
    BillStreet1,
    BillCity,
    BillState,
    BillPostalCode,
    ShipStreet1,
    ShipCity,
    ShipState,
    ShipPostalCode,
    AddressIsTheSame,
    TermsAndConditions,
  } = form;

  if (AddressIsTheSame) {
    return !isEmpty(ShipStreet1) &&
      !isEmpty(ShipCity) &&
      !isEmpty(ShipState) &&
      !isEmpty(ShipPostalCode) &&
      TermsAndConditions
      ? true
      : false;
  } else {
    return !isEmpty(BillStreet1) &&
      !isEmpty(BillCity) &&
      !isEmpty(BillState) &&
      !isEmpty(BillPostalCode) &&
      !isEmpty(ShipStreet1) &&
      !isEmpty(ShipCity) &&
      !isEmpty(ShipState) &&
      !isEmpty(ShipPostalCode) &&
      TermsAndConditions
      ? true
      : false;
  }
};

// China
const isCnFormValid = ({ form }) => {
  const { BillPhone, ConfirmBillPhone, TermsAndConditions, PhoneIsUnique } =
    form;

  return !isEmpty(BillPhone) &&
    !isEmpty(ConfirmBillPhone) &&
    TermsAndConditions &&
    BillPhone.length === 11 &&
    ConfirmBillPhone.length === 11 &&
    BillPhone === ConfirmBillPhone &&
    PhoneIsUnique
    ? true
    : false;
};

// Japan
const isJpFormValid = ({ form }) => {
  const {
    RomanizedFirstName,
    RomanizedLastName,
    BillStreet1,
    BillCity,
    BillState,
    BillPostalCode,
    BillPhone,
    ShipStreet1,
    ShipCity,
    ShipState,
    ShipPostalCode,
    ShipPhone,
    AddressIsTheSame,
    TermsAndConditions,
  } = form;

  if (AddressIsTheSame) {
    return !isEmpty(RomanizedFirstName) &&
      !isEmpty(RomanizedLastName) &&
      !isEmpty(ShipStreet1) &&
      !isEmpty(ShipCity) &&
      !isEmpty(ShipState) &&
      !isEmpty(ShipPostalCode) &&
      !isEmpty(ShipPhone) &&
      TermsAndConditions
      ? true
      : false;
  } else {
    return !isEmpty(RomanizedFirstName) &&
      !isEmpty(RomanizedLastName) &&
      !isEmpty(BillStreet1) &&
      !isEmpty(BillCity) &&
      !isEmpty(BillState) &&
      !isEmpty(BillPostalCode) &&
      !isEmpty(BillPhone) &&
      !isEmpty(ShipStreet1) &&
      !isEmpty(ShipCity) &&
      !isEmpty(ShipState) &&
      !isEmpty(ShipPostalCode) &&
      !isEmpty(ShipPhone) &&
      TermsAndConditions
      ? true
      : false;
  }
};

// Korea
const isKrFormValid = ({ form }) => {
  const {
    FirstName,
    LastName,
    BankAccountNumber,
    BankAccountName,
    BillStreet1,
    BillCity,
    BillState,
    BillPostalCode,
    ShipStreet1,
    ShipCity,
    ShipState,
    ShipPostalCode,
    AddressIsTheSame,
    TermsAndConditions,
    DateOfBirth,
    ResidentRegistrationNumber,
  } = form;

  const shipPostalCodeIsValid =
    fiveDigitsRegex.test(ShipPostalCode) || isEmpty(ShipPostalCode);
  const billPostalCodeIsValid =
    fiveDigitsRegex.test(BillPostalCode) || !BillPostalCode;

  if (AddressIsTheSame) {
    return !isEmpty(FirstName) &&
      !isEmpty(DateOfBirth) &&
      !isEmpty(ResidentRegistrationNumber) &&
      !isEmpty(BankAccountNumber) &&
      !isEmpty(BankAccountName) &&
      !isEmpty(LastName) &&
      !isEmpty(ShipStreet1) &&
      !isEmpty(ShipCity) &&
      !isEmpty(ShipState) &&
      shipPostalCodeIsValid &&
      TermsAndConditions
      ? true
      : false;
  } else {
    return !isEmpty(FirstName) &&
      !isEmpty(LastName) &&
      !isEmpty(DateOfBirth) &&
      !isEmpty(ResidentRegistrationNumber) &&
      !isEmpty(BankAccountNumber) &&
      !isEmpty(BankAccountName) &&
      !isEmpty(BillStreet1) &&
      !isEmpty(BillCity) &&
      !isEmpty(BillState) &&
      billPostalCodeIsValid &&
      !isEmpty(ShipStreet1) &&
      !isEmpty(ShipCity) &&
      !isEmpty(ShipState) &&
      shipPostalCodeIsValid &&
      TermsAndConditions
      ? true
      : false;
  }
};

// Singapore
const isSgFormValid = ({ form }) => {
  const {
    BillStreet1,
    BillCity,
    BillPostalCode,
    ShipStreet1,
    ShipCity,
    ShipPostalCode,
    AddressIsTheSame,
    TermsAndConditions,
  } = form;

  if (AddressIsTheSame) {
    return !isEmpty(ShipStreet1) &&
      !isEmpty(ShipCity) &&
      !isEmpty(ShipPostalCode) &&
      TermsAndConditions
      ? true
      : false;
  } else {
    return !isEmpty(BillStreet1) &&
      !isEmpty(BillCity) &&
      !isEmpty(BillPostalCode) &&
      !isEmpty(ShipStreet1) &&
      !isEmpty(ShipCity) &&
      !isEmpty(ShipPostalCode) &&
      TermsAndConditions
      ? true
      : false;
  }
};

// Netherlands
const isNlFormValid = ({ form }) => {
  const {
    BillStreet1,
    BillState,
    BillPostalCode,
    ShipStreet1,
    ShipState,
    ShipPostalCode,
    AddressIsTheSame,
    TermsAndConditions,
  } = form;

  if (AddressIsTheSame) {
    return !isEmpty(ShipStreet1) &&
      !isEmpty(ShipState) &&
      !isEmpty(ShipPostalCode) &&
      TermsAndConditions
      ? true
      : false;
  } else {
    return !isEmpty(BillStreet1) &&
      !isEmpty(BillState) &&
      !isEmpty(BillPostalCode) &&
      !isEmpty(ShipStreet1) &&
      !isEmpty(ShipState) &&
      !isEmpty(ShipPostalCode) &&
      TermsAndConditions
      ? true
      : false;
  }
};

// HKCB
const isHkcbFormValid = ({ form }) => {
  const {
    FirstName,
    LastName,
    Email,
    Password,
    BillStreet1,
    BillState,
    ShipStreet1,
    ShipStreet2,
    ShipState,
    ShipCity,
    ShipCounty,
    TermsAndConditions,
  } = form;

  return !isEmpty(BillStreet1) &&
    !isEmpty(BillState) &&
    !isEmpty(ShipStreet1) &&
    chineseCharactersDigitsSymbolsMax5English.test(ShipStreet1) &&
    !isEmpty(ShipStreet2) 
      ? chineseCharactersDigitsSymbolsMax5English.test(ShipStreet2)
      : true &&
    !isEmpty(ShipState) &&
    chineseCharactersDigitsSymbols.test(ShipState) &&
    !isEmpty(ShipCounty) &&
    chineseCharactersDigitsSymbols.test(ShipCounty) &&
    !isEmpty(ShipCity) &&
    chineseCharactersDigitsSymbols.test(ShipCity) &&
    !isEmpty(FirstName) &&
    !isEmpty(LastName) &&
    !isEmpty(Password) &&
    !isEmpty(Email) &&
    TermsAndConditions
    ? true
    : false;
};
