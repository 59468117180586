import { isArray } from 'lodash';
import { get } from './request';

export const bankApi = {
  getKoreanBanks: async () => {
    try {
      const res = await get('/api/getKoreanBankNames');

      if (typeof res === 'object' && isArray(res.recordset)) {
        return res.recordset;
      } else {
        return [];
      }
    } catch {
      throw new Error('Problem retrieving banks for Korea.');
    }
  },
};
