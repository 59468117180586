import { ReactComponent as CancelSVG } from 'assets/svg/large-x.svg';

/**
 * Used to wrap content that needs to be displayed as a modal.
 *
 * @param {string} id identifier.
 * @param {boolean} confirmationType enables scroll bar for content.
 * @param {boolean} cancelType enables top right cancel button.
 * @param {function} close callback function on close.
 * @returns a modal wrapper.
 */
export default function Modal({
  id = '',
  confirmationType = true,
  cancelType = true,
  close = () => {},
  children,
}) {
  const modalWrapperClass = confirmationType
    ? 'modal--wrapper confirmation-modal fade-in'
    : 'modal--wrapper fade-in';

  const modalWrapperStyle = confirmationType
    ? { alignItems: 'flex-start' }
    : {};

  return (
    <div className={modalWrapperClass} style={modalWrapperStyle}>
      <div className="modal" id={id} data-testid={id}>
        {cancelType && (
          <button
            data-testid={`${id?.replace(/\W/g, '')}_btn`}
            className="modal-close"
            onClick={() => close()}
          >
            <CancelSVG />
          </button>
        )}
        {children}
      </div>
    </div>
  );
}
