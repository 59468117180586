import { useEffect, useState } from "react";
import { addressApi } from "api/address";
import { bankApi } from "api/bank";
import DistributorInfoSection from "components/common/sections/DistributorInfoSection";
import { useForm } from "contexts/FormContext";
import ThFormShippingAddressSection from "./ThFormShippingAddressSection";
import ThFormBillingAddressSection from "./ThFormBillingAddressSection";

export default function ThForm({ data, market }) {
  // Context
  const { form } = useForm();

  // State
  const [states, setStates] = useState([]);

  // Effects
  useEffect(() => {
    const getThailandStates = async () => {
      const res = await addressApi.getCountryStates(market);

      if (res.length !== 0) {
        // Parse states to be rendered by select dropdown input.
        const options = res.map((r) => ({
          id: r.Title,
          text: r.Title,
        }));
        setStates(options);
      }
    };
    getThailandStates();
  }, [market]);

  // Contentful
  const { reference2, reference3, reference4, reference5, reference6 } =
    data.fields;

  return (
    <>
      <DistributorInfoSection data={reference2} market={market} />
      <ThFormShippingAddressSection states={states} data={reference6} />
      {!form.AddressIsTheSame && (
        <ThFormBillingAddressSection states={states} data={reference5} />
      )}
    </>
  );
}
