import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { marketApi } from "api/market";
import Layout from "components/common/layouts/Layout";
import SelectInput from "components/common/inputs/SelectInput";
import { useContentful } from "hooks/useContentful";
import { marketToPathMap } from "constants/market";
import { MarketFlagComponent } from "../modules/language/MarketFlag";

export default function HomePage() {
  // Custom Hooks
  // get contentful data - failure leaves page blank
  const data = useContentful("4JuoC9yDI1i9HuLmjB4Pn0"); // Migrations - Home Page

  // State
  const [market, setMarket] = useState("");
  const [markets, setMarkets] = useState([]);

  // Effects
  // on page load get markets from backend - failure leaves a dropdown that has no data - no error shown and nothing can be done by user
  useEffect(() => {
    const getMigrationMarkets = async () => {
      const res = await marketApi.getMigrationMarkets();
      if (res.length > 0) {
        // Filter markets to be rendered by select dropdown input.
        const visibleMarkets = res.filter((r) => r.isVisible === 1);
        // Parse visible markets to be rendered by select dropdown input.
        const options = visibleMarkets.map((m) => ({
          id: m.countryName,
          text: m.countryName,
          path: marketToPathMap[market.text],
        }));
        setMarkets(options);
      }
    };
    getMigrationMarkets();
  }, []);
  // if no data from contentful page is blank
  if (!data) {
    return null;
  }

  // Contentful
  const { title, description, text1, buttonText1: continueText } = data?.fields;
  // add flag and flag styles to market
  const options = markets.map((market) => {
    return {
      ...market,
      flag: MarketFlagComponent[marketToPathMap[market.id]],
      styles: { height: "15px" },
    };
  });

  // Url Path
  const path = marketToPathMap[market];

  return (
    <Layout enableLanguageSelector={false} data={data}>
      <section className='intro-text center map mt-20'>
        <h1>{title}</h1>
        <p className='text-center wide-desktop mt-20'>{description}</p>
        <div className='form-max flex-20 column mt-20'>
          <SelectInput
            labelText={text1}
            options={options}
            value={market}
            onChange={(e) => setMarket(e.target.id)}
            className='migration'
          />
        </div>
        <div className='mt-40'>
          <Link to={`/${path}/migration-form`}>
            <button className='button' type='button' disabled={isEmpty(market)}>
              {continueText}
            </button>
          </Link>
        </div>
      </section>
    </Layout>
  );
}
