import { Routes, Route } from 'react-router-dom';
import { FormContextProvider } from 'contexts/FormContext';
import NlMigrationFormPage from './migration-form';

export default function NlMigrationRoutes() {
  return (
    <FormContextProvider market="nl">
      <Routes>
        <Route path="migration-form" element={<NlMigrationFormPage />} />
      </Routes>
    </FormContextProvider>
  );
}
