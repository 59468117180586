import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useContentful } from "hooks/useContentful";
import FormAddressText from "components/common/texts/FormAddressText";
import { TextButton } from "components/common/buttons/TextButton";
import Modal from "./Modal";

export default function InvalidAddressModal({
  addressType,
  form,
  close,
  so,
  callback,
  cancelCallback,
}) {
  const data = useContentful("13WTOIgb7X5J90rxMmfN1l"); // (EDIT ONLY) Invalid Address Modal Info

  if (!data) {
    return null;
  }

  const {
    title,
    item1: shippingText,
    item2: billingText,
    item3,
    buttonText,
    buttonText2,
    richText,
  } = data.fields;

  return (
    <Modal id='invalid-address-modal' cancelType={false}>
      <section className='modal-title'>
        <h2>{title}</h2>
      </section>
      <section className='modal-content'>
        <span className='text-center'>
          {documentToReactComponents(richText)}
        </span>
        {addressType === "Ship" && (
          <p className='text-center mb-40'>{shippingText}</p>
        )}
        {addressType === "Bill" && (
          <p className='text-center mb-40'>{billingText}</p>
        )}
        <h3 className='light text-center'>{item3}</h3>
        <div className='center'>
          <FormAddressText addressType={addressType} form={form} />
        </div>
      </section>
      <div className='modal-footer double-button-modal'>
        <button
          data-testid={`${buttonText?.replace(/\W/g, "").toLowerCase()}_btn`}
          className='button'
          onClick={() => {
            callback({ overwrite: false, so });
            close();
          }}
        >
          {buttonText2}
        </button>
        <TextButton
          data-testid={`${buttonText?.replace(/\W/g, "").toLowerCase()}_btn`}
          onClick={() => {
            cancelCallback();
            close();
          }}
          title={buttonText}
        />
      </div>
    </Modal>
  );
}
