import { useEffect } from "react";
import ShadowCard from "components/common/cards/ShadowCard";
import TextInputForm from "components/common/forms/TextInputForm";
import { useForm } from "contexts/FormContext";
import { chineseCharactersDigitsSymbols, chineseCharactersDigitsSymbolsMax5English } from "constants/regex";

export default function HkcbFormShippingAddressSection({ data }) {
  // Context
  const { form, inputOnChange, validateForm } = useForm();

  // Effects
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.AddressIsTheSame]);

  // Contentful
  const { title, text1, text2, text3, inputModelArray } = data.fields;

  // inputNames need to match the order of models in inputModelArray.
  const inputNames = [
    "shipping-address-state",
    "shipping-address-city",
    "shipping-address-county",
    "shipping-address-line-1",
    "shipping-address-line-2",
  ];
  const inputPatternArray = [
    chineseCharactersDigitsSymbols,
    chineseCharactersDigitsSymbols,
    chineseCharactersDigitsSymbols,
    chineseCharactersDigitsSymbolsMax5English,
    chineseCharactersDigitsSymbolsMax5English,
  ];

  return (
    <ShadowCard label={title}>
      <div className='form-max flex-20 column'>
        {/* <p>{text3}</p> */}
        <p className='address-country'>
          {text1}
          <strong>{text2}</strong>
        </p>
        <TextInputForm
          form={form}
          validateForm={validateForm}
          inputs={inputModelArray}
          inputNames={inputNames}
          inputOnChange={inputOnChange}
          inputBlurPatterns={inputPatternArray}
        />
      </div>
    </ShadowCard>
  );
}
