import { useRef, useState, createElement } from "react";
import { isEmpty } from "lodash";
import useOutsideAlerter from "hooks/useOutsideAlerter";

/**
 * Creates a form select dropdown.
 * https://corporate-ux.azurewebsites.net/ref/components-form-elements-form#properties
 *
 * @param {boolean} active Whether the select dropdown is currently clicked into.
 * @param {function} setActive Setter function for active state.
 * @param {string} labelText The label text of the select element.
 * @param {Object[]} options The options shown in the dropdown.
 * @param {string} options[].text - The text of the option.
 * @param {string} value The current option selected.
 * @param {function} onChange The handler function that changes the current selected value.
 */
export default function SelectInput({
  selectId,
  labelText,
  options = [],
  value,
  onChange,
  className,
}) {
  // Ref
  const wrapperRef = useRef(null);

  // Custom Hooks
  useOutsideAlerter(wrapperRef, () => setActive(false));

  // State
  const [active, setActive] = useState(false);

  const onSelect = (e) => {
    setActive(!active);
    onChange(e);
  };

  const selectBoxWrapperStatus = active
    ? "active"
    : !isEmpty(value)
    ? "completed"
    : "";

  let dropdownDirection = "";

  if (active) {
    dropdownDirection = getDirection(selectId);
  }

  return (
    <div className={`input-wrapper${className ? " " + className : ""}`}>
      <div
        id={selectId}
        className={`select-box-wrapper ${selectBoxWrapperStatus} ${dropdownDirection}`}
        ref={wrapperRef}
      >
        <button
          data-testid={selectId}
          type="button"
          id={selectId}
          className="selector"
          onClick={() => setActive(!active)}
          disabled={!options?.length}
        >
          <span className="selector-text">{value}</span>
          <p className="label" htmlFor={selectId} aria-label={selectId}>
            {labelText}
          </p>
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.796875 1L7.04263 7L13.2884 1"
              stroke="#747679"
              strokeWidth="2"
            />
          </svg>
        </button>
        <ul id={`${selectId}-list`}>
          {options.map((option, index) => (
            <li
              id={option.id}
              data-testid={`${selectId}${option.text?.replace(/\W/g, "")}`}
              className={`${option.text === value ? "selected" : ""}`}
              tabIndex="0"
              key={index}
              onClick={(e) => onSelect(e)}
            >
              {option.flag && createElement(option.flag, option.styles)}
              {option.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const getDirection = (id) => {
  try {
    let direction = "";

    const selectEl = document.querySelector(`#${id}-list`);
    const bounding = selectEl.getBoundingClientRect();

    // TODO: Implement programmatic way of assigning max height to select dropdowns.
    const maxHeight = 200;

    if (
      bounding.bottom + maxHeight >
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      direction = "upward";
    }
    return direction;
  } catch {
    return "";
  }
};
