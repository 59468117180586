import { useContentful } from "hooks/useContentful";
import Modal from "./Modal";

export default function CancelModal({ close, overrideButtonUrl }) {
  const data = useContentful("2Zf88aDALwIQyQnnSylRR5"); // Cancel Modal Info

  if (!data) {
    return null;
  }

  const { title, item1, buttonText, buttonUrl } = data.fields;

  return (
    <Modal id="cancel-modal" close={close}>
      <section className="intro-text center">
        <h2 className="mt-20">{title}</h2>
        <p className="text-center wide-desktop">{item1}</p>
      </section>
      <div className="mt-20 center">
        <button
          data-testid={`${title?.replace(/\W/g, "").toLowerCase()}_btn`}
          className="button"
          onClick={() => (window.location.href = overrideButtonUrl ?? buttonUrl)}
        >
          {buttonText}
        </button>
      </div>
    </Modal>
  );
}
