export default function InvalidJoinDateFooter({ data }) {
  // Contentful
  const {
    item5: invalidJoinDateText,
    buttonText2: dashText,
    buttonUrl2: dashUrl,
  } = data?.fields;
  return (
    <section className="card--container">
      <div className="inner-content mb-20">
        <div className="form-max flex-20 column">
          <p className="text-center">{invalidJoinDateText}</p>
        </div>
      </div>
      <button
        className="button"
        type="button"
        onClick={() => (window.location.href = dashUrl)}
      >
        {dashText}
      </button>
    </section>
  );
}
