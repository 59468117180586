import { useEffect, useMemo } from 'react';
import ShadowCard from 'components/common/cards/ShadowCard';
import TextInputForm from 'components/common/forms/TextInputForm';
import SelectInput from 'components/common/inputs/SelectInput';
import { useForm } from 'contexts/FormContext';
import { hangulRegex, numericRegex } from 'constants/regex';

export default function KrFormBankInformationSection({ banks = [], data }) {
  // Context
  const { form, setForm, inputOnChange, validateForm } = useForm();

  // Effects
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.BillState]);

  // inputNames need to match the order of models coming from contentful.
  const inputNames = ['bank-name', 'bank-account-no', 'bank-account-name'];
  const inputPatterns = [hangulRegex, numericRegex, ];
  const inputRequired = [false, true, true];
  const inputDynamicHelperText = [false, false, true];

  // Contentful
  const { title, inputModelArray } = data.fields;

  // Select Input
  const selectInput = inputModelArray.slice().shift();
  const selectInputName = inputNames.slice().shift();

  // Text Inputs
  const textInputs = inputModelArray.slice(1);
  const textInputNames = inputNames.slice(1);
  const textInputPatterns = inputPatterns.slice(1);
  const textInputRequired = inputRequired.slice(1);
  const textInputDynamicHelperText = inputDynamicHelperText.slice(1);

  const onSelectChange = useMemo(
    () => (e) => {
      const { id } = e.target;
      setForm({ ...form, BankName: id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setForm, form]
  );

  return (
    <ShadowCard label={title}>
      <div className='form-max flex-20 column' id="bank-information">
        <SelectInput
          selectId={selectInputName}
          labelText={selectInput?.fields?.name}
          options={banks}
          value={form.BankName}
          onChange={onSelectChange}
        />
        <TextInputForm
          form={form}
          validateForm={validateForm}
          inputs={textInputs}
          inputNames={textInputNames}
          inputPatterns={textInputPatterns}
          inputOnChange={inputOnChange}
          inputRequired={textInputRequired}
          inputDynamicHelperText={textInputDynamicHelperText}
        />
      </div>
    </ShadowCard>
  );
}
