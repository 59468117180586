export default function RepData(props) {
  const { data } = props;
  if (typeof data === "string") return <Single {...props} />;
  return <Array data={data} />;
}
function Single(props) {
  const { label = "", data = "" } = props;
  return (
    <p className='text-center wide-desktop'>
      <span className='bold'>{label}</span>
      <br />
      {data}
    </p>
  );
}
function Array(props) {
  const { data } = props;
  return data.map(({ label, data }) => {
    if (label && !data) return <span key={label}>{label}</span>;
    return <Single key={label} label={label} data={data} />;
  });
}
