import { useEffect, useMemo } from 'react';
import ShadowCard from 'components/common/cards/ShadowCard';
import SelectInput from 'components/common/inputs/SelectInput';
import TextInput from 'components/common/inputs/TextInput';
import {
  koreanAddressRegex,
  hangulRegex,
  fiveDigitsRegex,
} from 'constants/regex';
import { useForm } from 'contexts/FormContext';

export default function KrFormBillingAddressSection({ states = [], data }) {
  // Context
  const { form, setForm, inputOnChange, validateForm } = useForm();

  // Effects
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.BillState]);

  // Contentful
  const {
    title,
    text1,
    text2,
    reference1: {
      fields: { listArray },
    },
  } = data.fields;

  const onSelectChange = useMemo(
    () => (e) => {
      const { id } = e.target;
      setForm({ ...form, BillState: id });
    },
    [form, setForm]
  );

  return (
    <ShadowCard label={title}>
      <div className='form-max flex-20 column'>
        <p className='address-country'>
          {text1}
          <strong>{text2}</strong>
        </p>
        <SelectInput
          selectId='billing-address-state'
          labelText={listArray[0].fields?.name}
          options={states}
          value={form.BillState}
          onChange={onSelectChange}
        />
        <TextInput
          inputId='BillCity'
          inputName='billing-address-city'
          inputPattern={hangulRegex}
          inputValue={form.BillCity}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          label={listArray[1]?.fields?.name}
          helperText={listArray[1]?.fields?.helperText}
          inputErrorText={listArray[1]?.fields?.errorText}
          dynamicHelperText
          required
        />
        <TextInput
          inputId='BillStreet1'
          inputName='billing-address-line-1'
          onBlurPattern={koreanAddressRegex}
          inputValue={form.BillStreet1}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          label={listArray[2]?.fields?.name}
          helperText={listArray[2]?.fields?.helperText}
          inputErrorText={listArray[2]?.fields?.errorText}
          dynamicHelperText
          required
        />
        <TextInput
          inputId='BillStreet2'
          inputName='billing-address-line-2'
          onBlurPattern={koreanAddressRegex}
          inputValue={form.BillStreet2}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          label={listArray[3]?.fields?.name}
          helperText={listArray[3]?.fields?.helperText}
          inputErrorText={listArray[3]?.fields?.errorText}
          dynamicHelperText
        />
        <TextInput
          inputId='BillPostalCode'
          inputName='billing-address-postal'
          inputValue={form.BillPostalCode}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          onBlurPattern={form.BillPostalCode ? fiveDigitsRegex : null}
          label={listArray[4]?.fields?.name}
          helperText={listArray[4]?.fields?.helperText}
          inputErrorText={listArray[4]?.fields?.errorText}
          mask='zipCode'
        />
      </div>
    </ShadowCard>
  );
}
