import { useContentful } from "hooks/useContentful";

export default function RequestSubmitted(props) {
  const data = useContentful("6KkuiMz9j73IAxzqh12a6H"); // Form Request Submitted Info
  const { market, navUrl } = props;
  if (!data) {
    return null;
  }

  const {
    backgroundImage: {
      fields: {
        file: { url: imgSrc },
        title: imgAlt,
      },
    },
    title,
    item1,
    buttonText,
    buttonUrl,
  } = data.fields;
  const navigateUrl = navUrl || buttonUrl;

  return (
    <div className='request-submitted'>
      <section className='intro-text center'>
        <img src={imgSrc} alt={imgAlt} className='check-img' />
        <h1 className='text-center wide-desktop'>{title}</h1>
        <p className='text-center wide-desktop'>{item1}</p>
        <button
          data-testid='req_submitted'
          className='button'
          type='button'
          onClick={() => (window.location.href = navigateUrl)}
        >
          {buttonText}
        </button>
      </section>
    </div>
  );
}
