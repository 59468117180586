import {
  initialState,
  initialStateCn,
  initialStateJp,
  initialStateKr,
  initialStateHkcb,
} from "constants/form";
import { pathToMarketMap } from "constants/market";

// Initialize form by market.
export const getInitialStateByMarket = (market) => {
  switch (market) {
    case "cn":
      return initialStateCn;
    case "jp":
      return initialStateJp;
    case "kr":
      return initialStateKr;
    case "hkcb":
      return initialStateHkcb;
    default:
      return initialState;
  }
};

export const parseMigrationDataByMarket = (market, locale, rep, form) => {
  // If address is the toggled as the same, send shipping address for billing address.
  const addressType = form?.AddressIsTheSame ? "Ship" : "Bill";
  const marketName = pathToMarketMap[market];

  // Default data object sent.
  const repData = {
    Market: marketName,
    ShipCountry: marketName,
    Country: marketName,
    RepID: rep.RepID,
    FirstName: rep.FirstName,
    LastName: rep.LastName,
    CompanyName: rep.Company,
    DefaultLocaleID: rep.DefaultLocaleID,
    Email: rep.Email,
    RepDID: rep.RepDID,
    SponsorRepDID: rep.SponsorRepDID,
    JoinDate: rep.JoinDate,
    URL: rep.URL,
    UplineRepDID: rep.UplineRepDID,
    PreferredCulture: rep.PreferredCulture,
    DateOfBirth: rep.DateofBirth,
    Address1: rep.BillStreet1,
    Address2: rep.BillStreet2,
    City: rep.BillCity,
    State: rep.BillState,
    PostalCode: rep.BillPostalCode,
    County: "",
    Phone: rep.Phone1,
    ShipAddress1: rep.ShipStreet1,
    ShipAddress2: rep.ShipStreet2,
    ShipCity: rep.ShipCity,
    ShipState: rep.ShipState,
    ShipPostalCode: rep.ShipPostalCode,
    ShipCounty: "",
    ShipPhone: rep.Phone1,
    Locale: locale,
    AddressValidated: false,
    ShipAddressValidated: false,
  };

  // Used for every market except China.
  const addressData = {
    Address1: form[`${addressType}Street1`],
    Address2: form[`${addressType}Street2`],
    City: form[`${addressType}City`],
    State: form[`${addressType}State`],
    PostalCode: form[`${addressType}PostalCode`],
    ShipAddress1: form.ShipStreet1,
    ShipAddress2: form.ShipStreet2,
    ShipCity: form.ShipCity,
    ShipState: form.ShipState,
    ShipPostalCode: form.ShipPostalCode,
    AddressValidated: true,
    ShipAddressValidated: true,
  };

  switch (market) {
    case "cn":
      return {
        ...repData,
        Phone: form.BillPhone,
        ShipPhone: form.BillPhone,
      };
    case "jp":
      return {
        ...repData,
        ...addressData,
        RomanizedFirstName: form.RomanizedFirstName,
        RomanizedLastName: form.RomanizedLastName,
      };
    case "hkcb":
      return {
        ...repData,
        Address1: form[`${addressType}Street1`],
        Address2: form[`${addressType}Street2`],
        City: form[`${addressType}City`],
        State: form[`${addressType}State`],
        PostalCode: form[`${addressType}PostalCode`],
        ShipAddress1: form.ShipStreet1,
        ShipAddress2: form.ShipStreet2,
        ShipCity: form.ShipCity,
        ShipState: form.ShipState,
        AddressValidated: true,
        ShipAddressValidated: true,
        ShipCounty: form.ShipCounty,
        FirstName: form.FirstName,
        LastName: form.LastName,
        Company: form.Company,
        Email: form.Email,
        Password: form.Password,
      };
    case "kr":
      return {
        ...repData,
        ...addressData,
        FirstName: form.FirstName,
        LastName: form.LastName,
        BankName: form.BankName,
        BankAccountNumber: form.BankAccountNumber,
        BankAccountName: form.BankAccountName,
        BankAccountUsername: form.BankAccountUsername,
        DateOfBirth: form.DateOfBirth,
        GovernmentID: form.ResidentRegistrationNumber,
      };
    default:
      return { ...repData, ...addressData };
  }
};

// Switch lookup table for name attribute conversion to which form object property it references.
export const inputIdFromNameMap = {
  fname: "FirstName",
  lname: "LastName",
  rfname: "RomanizedFirstName",
  rlname: "RomanizedLastName",
  "bank-name": "BankName",
  "bank-account-no": "BankAccountNumber",
  "bank-account-name": "BankAccountName",
  "bank-account-username": "BankAccountUsername",
  "billing-address-line-1": "BillStreet1",
  "billing-address-line-2": "BillStreet2",
  "billing-address-city": "BillCity",
  "billing-address-state": "BillState",
  "billing-address-postal": "BillPostalCode",
  "billing-address-phone": "BillPhone",
  "confirm-billing-address-phone": "ConfirmBillPhone",
  "shipping-address-line-1": "ShipStreet1",
  "shipping-address-line-2": "ShipStreet2",
  "shipping-address-city": "ShipCity",
  "shipping-address-state": "ShipState",
  "shipping-address-county": "ShipCounty",
  "shipping-address-postal": "ShipPostalCode",
  "shipping-address-phone": "ShipPhone",
  "resident-registration-number": "ResidentRegistrationNumber",
  company: "Company",
  email: "Email",
  password: "Password",
  governmentId: "GovernmentId",
};

// Switch lookup table for name attribute conversion to autocomplete attribute.
export const autoCompleteAttrFromNameMap = {
  fname: "given-name",
  lname: "family-name",
  rfname: "given-name",
  rlname: "family-name",
  "billing-address-line-1": "address-line1",
  "billing-address-line-2": "address-line2",
  "billing-address-state": "address-level1",
  "billing-address-city": "address-level2",
  "billing-address-postal": "postal-code",
  "shipping-address-line-1": "address-line1",
  "shipping-address-line-2": "address-line2",
  "shipping-address-state": "address-level1",
  "shipping-address-city": "address-level2",
  "shipping-address-postal": "postal-code",
  "resident-registration-number": "ResidentRegistrationNumber",
  "date-of-birth": "DateOfBirth",
  email: "email",
  password: "current-password",
  company: "organization",
};


export const createInputIdFromName = nameAttr => inputIdFromNameMap[nameAttr] || nameAttr;