/**
 * Docs: https://corporate-ux.azurewebsites.net/enrollment/loader-documentation.php
 *
 * @param {boolean} active whether the loader is active or not.
 * @returns a loader that is used when a user must wait when there is a system action blocking the loading of content.
 */

export default function Loader({ active = false }) {
  if (!active) {
    return null;
  }

  return (
    <div className="modal--wrapper loader-state fade-in">
      <LoaderRing active={active} />
    </div>
  );
}

export function LoaderRing({ active = false }) {
  if (!active) {
    return null;
  }

  return (
    <div className="loader-ring fadeIn">
      <div></div>
    </div>
  );
}
