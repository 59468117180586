export const defaultLanguage = {
  id: 1,
  name: 'English',
  code: 'en',
  locale: 'en-US',
};

export const defaultCnLanguage = {
  id: 2,
  name: '简体中文',
  code: 'zh-HK',
  locale: 'zh-Hans-HK',
};
