import { isArray } from 'lodash';
import { get } from './request';

export const marketApi = {
  getMigrationMarkets: async () => {
    try {
      const res = await get('/api/getMigrationDropDown');

      if (typeof res === 'object' && isArray(res.recordset)) {
        return res.recordset;
      } else {
        return [];
      }
    } catch {
      throw new Error('Problem retrieving markets for migration.');
    }
  },
};
