import { useEffect, useMemo } from "react";
import ShadowCard from "components/common/cards/ShadowCard";
import TextInputForm from "components/common/forms/TextInputForm";
import SelectInput from "components/common/inputs/SelectInput";
import { useForm } from "contexts/FormContext";

export default function MyFormBillingAddressSection({ states = [], data }) {
  // Context
  const { form, setForm, inputOnChange, validateForm } = useForm();

  // Effects
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.BillState]);

  // Contentful
  const { title, text1, text2, inputModelArray } = data.fields;

  // inputNames need to match the order of models in inputModelArray.
  const inputNames = [
    "billing-address-line-1",
    "billing-address-line-2",
    "billing-address-postal",
    "billing-address-city",
    "billing-address-state",
  ];

  // Text Inputs
  const textInputs = inputModelArray.slice(0, -1);
  const textInputNames = inputNames.slice(0, -1);

  // Select Input
  const selectInput = inputModelArray.slice().pop();
  const selectInputName = inputNames.slice().pop();

  const onSelectChange = useMemo(
    () => (e) => {
      const { id } = e.target;
      setForm({ ...form, BillState: id });
    },
    [form, setForm]
  );

  return (
    <ShadowCard label={title}>
      <div className="form-max flex-20 column">
        <p className="address-country">
          {text1}
          <strong>{text2}</strong>
        </p>
        <TextInputForm
          form={form}
          validateForm={validateForm}
          inputs={textInputs}
          inputNames={textInputNames}
          inputOnChange={inputOnChange}
        />
        <SelectInput
          selectId={selectInputName}
          labelText={selectInput?.fields?.name}
          options={states}
          value={form.BillState}
          onChange={onSelectChange}
        />
      </div>
    </ShadowCard>
  );
}
