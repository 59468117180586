import { useState } from "react";
import { isEmpty } from "lodash";
import { formApi } from "api/form";
import ShadowCard from "components/common/cards/ShadowCard";
import CountryCodePhoneInput from "components/common/inputs/CountryCodePhoneInput";
import ValidCompleteIcon from "components/common/icons/ValidCompleteIcon";
import ValidErrorIcon from "components/common/icons/ValidErrorIcon";
import { useApp } from "contexts/AppContext";
import { useForm } from "contexts/FormContext";
import { inputIdFromNameMap } from "helpers/form";

export default function CnForm({ data, previousPhoneUsed }) {
  // Context
  const {
    state: { rep },
  } = useApp();
  const {
    form,
    inputOnChange,
    validateForm,
    validateFormWithUniquePhoneNumber,
  } = useForm();

  // State
  const [hasInput, setHasInput] = useState(false);
  const [isTenDigitsOrMore, setIsTenDigitsOrMore] = useState(false);
  const [isMatching, setIsMatching] = useState(false);
  const [hasCheckedUnique, setHasCheckedUnique] = useState(false);

  const validateInputs = () => {
    const { BillPhone, ConfirmBillPhone } = form;

    // Check if fields are not empty.
    if (!isEmpty(BillPhone) || !isEmpty(ConfirmBillPhone)) {
      setHasInput(true);
      // Check if phone number is at least 10 digits.
      if (BillPhone.length === 11 || ConfirmBillPhone.length === 11) {
        setIsTenDigitsOrMore(true);
        // If both numbers are the same, check if number has already been used.
        if (BillPhone === ConfirmBillPhone) {
          setIsMatching(true);
          checkForUniquePhoneNumber();
        } else {
          setIsMatching(false);
          setHasCheckedUnique(false);
          validateForm();
        }
      } else {
        setIsTenDigitsOrMore(false);
        validateForm();
      }
    } else {
      setHasInput(false);
      validateForm();
    }
  };

  const checkForUniquePhoneNumber = async () => {
    const ttnRes = await formApi.validateTTNPhone({ form });

    // Phone is only unique if both APIs pass off as true. (Refactored to only TTN API needs to be true.)
    const PhoneIsUnique = ttnRes;

    validateFormWithUniquePhoneNumber({ PhoneIsUnique });
    setHasCheckedUnique(true);
  };

  const { RepDID, SponsorRepDID, FirstName, LastName } = rep;
  const {
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
    text9,
    image1: {
      fields: {
        file: { url: imgSrc },
        title: imgAlt,
      },
    },
    inputModelArray,
  } = data.fields;

  const inputNames = ["billing-address-phone", "confirm-billing-address-phone"];
  const code = "+86"; // China Dialing Code

  return (
    <ShadowCard>
      <div className="form-max flex-20 column">
        <p className="text-center wide-desktop">
          <span className="bold">{text1}</span>
          <br />
          {RepDID}
        </p>
        <p className="text-center wide-desktop">
          <span className="bold">{text2}</span>
          <br />
          {SponsorRepDID}
        </p>
        <p className="text-center wide-desktop">
          <span className="bold">{text3}</span>
          <br />
          {FirstName} {LastName}
        </p>
        <p className="text-center wide-desktop">
          <span className="bold">{text4}</span>
        </p>
        {inputModelArray.map((inputModel, index) => {
          const inputName = inputNames[index];
          const inputId = inputIdFromNameMap[inputName] || inputName;
          const { name, helperText } = inputModel.fields;
          return (
            <CountryCodePhoneInput
              imgSrc={imgSrc}
              imgAlt={imgAlt}
              code={code}
              inputId={inputId}
              inputName={inputName}
              inputValue={form[inputId]}
              inputOnChange={inputOnChange}
              inputOnBlur={validateInputs}
              label={name}
              helperText={helperText}
              key={index}
            />
          );
        })}
        {hasInput && (
          <div className="center">
            <ul className="validation-list active left">
              <li id="check-match text-center">
                {isTenDigitsOrMore ? <ValidCompleteIcon /> : <ValidErrorIcon />}
                <p>{text7}</p>
              </li>
              {isTenDigitsOrMore && (
                <li id="check-match text-center">
                  {isMatching ? <ValidCompleteIcon /> : <ValidErrorIcon />}
                  <p>{text5}</p>
                </li>
              )}
              {hasCheckedUnique && (
                <li id="check-unique text-center">
                  {form.PhoneIsUnique ? (
                    <ValidCompleteIcon />
                  ) : (
                    <ValidErrorIcon />
                  )}
                  <p>{text6}</p>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      {isEmpty(previousPhoneUsed) ? (
        <p className="mt-20 helper-text text-center">{text8}</p>
      ) : (
        <>
          <p className="mt-20 helper-text text-center">{text9}</p>
          <p className="helper-text text-center">{previousPhoneUsed}</p>
        </>
      )}
    </ShadowCard>
  );
}
