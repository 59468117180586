import { useEffect, useState } from 'react';
import { addressApi } from 'api/address';
import { useForm } from 'contexts/FormContext';
import MyFormDistributorInfoSection from './MyFormDistributorInfoSection';
import MyFormShippingAddressSection from './MyFormShippingAddressSection';
import MyFormBillingAddressSection from './MyFormBillingAddressSection';

export default function MyForm({ data }) {
  // Context
  const { form, market } = useForm();

  // State
  const [states, setStates] = useState([]);

  // Effects
  useEffect(() => {
    const getMalaysiaStates = async () => {
      const res = await addressApi.getCountryStates(market);

      if (res.length !== 0) {
        // Parse states to be rendered by select dropdown input.
        const options = res.map((r) => ({
          id: r.Title,
          text: r.Title,
        }));
        setStates(options);
      }
    };
    getMalaysiaStates();
  }, [market]);

  // Contentful
  const { banner2Ref, banner3Ref, banner4Ref } = data.fields;

  return (
    <>
      <MyFormDistributorInfoSection data={banner2Ref} />
      <MyFormShippingAddressSection states={states} data={banner4Ref} />
      {!form.AddressIsTheSame && (
        <MyFormBillingAddressSection states={states} data={banner3Ref} />
      )}
    </>
  );
}
