import { useEffect, useState } from "react";
import { addressApi } from "api/address";
import { formApi } from "api/form";
import RequestSubmitted from "components/common/RequestSubmitted";
import FormFooter from "components/common/footers/FormFooter";
import FormHeader from "components/common/headers/FormHeader";
import Layout from "components/common/layouts/Layout";
import SgForm from "components/sg/SgForm";
import { useApp } from "contexts/AppContext";
import { useForm } from "contexts/FormContext";
import { useModal } from "contexts/ModalContext";
import { isSoValid, parseAddressFormFieldsFromSo } from "helpers/so";
import { useContentful } from "hooks/useContentful";
import Loader from "components/common/loaders/Loader";

export default function SgMigrationFormPage() {
  // Context
  const {
    state: { locale, rep },
  } = useApp();
  const { form, market, overwriteAddress } = useForm();
  const { open } = useModal();

  // Custom Hooks
  const data = useContentful("292678SforK7g87qOBfTv1"); // Singapore Form Page

  // State
  const [success, setSuccess] = useState(false); // Tracks whether to render form complete.
  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks when the saveMigrationData call is in progress
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      saveMigrationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  if (!data) {
    return null;
  }

  const saveMigrationData = async () => {
    try {
      setIsLoading(true);
      const res = await formApi.saveMigrationData({
        market,
        locale,
        rep,
        form,
      });
  
      if (typeof res === "string") {
        if (res === "Created new user") {
          console.log("Migration saved");
          setSuccess(true);
          return;
        } else if (res === "Contains illegal characters") {
          open({ type: "illegal-characters" });
        } else {
          // TODO: Error handling.
        }
      }
      throw new Error(res); // Throw so we reenable the save button
    } catch (err) {
      console.error("Migration failed", err);
      setIsSubmitting(false);
    } finally {
      setIsLoading(false);
    }
  };

  const validateAddress = async ({ addressType, callback = () => {} }) => {
    setIsLoading(true);
    // Validate address against service objects.
    const country = "Singapore";
    const so = await addressApi.validate({ addressType, form, country });
    setIsLoading(false);
    if (isSoValid({ so })) {
      open({
        type: "confirm-address",
        props: { addressType, form, so },
        callback,
      });
    } else {
      open({
        type: "invalid-address",
        props: { addressType, form },
      });
    }
  };

  const validateShippingOnly = () => {
    const addressType = "Ship";
    validateAddress({
      addressType,
      callback: ({ overwrite, so }) => {
        if (overwrite) {
          const address = parseAddressFormFieldsFromSo({ addressType, so });
          overwriteAddress({ address });
        }
        setIsSubmitting(true);
      },
    });
  };

  const validateShippingAndBilling = () => {
    let address = {};
    validateAddress({
      addressType: "Ship",
      callback: ({ overwrite, so }) => {
        if (overwrite) {
          address = {
            ...address,
            ...parseAddressFormFieldsFromSo({ addressType: "Ship", so }),
          };
        }
        validateAddress({
          addressType: "Bill",
          callback: ({ overwrite, so }) => {
            if (overwrite) {
              address = {
                ...address,
                ...parseAddressFormFieldsFromSo({ addressType: "Bill", so }),
              };
            }
            overwriteAddress({ address });
            setIsSubmitting(true);
          },
        });
      },
    });
  };

  const onConfirm = () => {
    if (form.AddressIsTheSame) {
      validateShippingOnly();
    } else {
      validateShippingAndBilling();
    }
  };

  const onFormSubmit = () => {
    open({
      type: "confirm-migration",
      props: { market },
      callback: onConfirm,
    });
  };

  const { banner1Ref } = data.fields;

  return (
    <Layout data={banner1Ref}>
      {!success ? (
        <>
          {isLoading && <Loader active />}
          <FormHeader data={banner1Ref} />
          <SgForm data={data} />
          <FormFooter data={banner1Ref} onFormSubmit={onFormSubmit} isSubmitting={isSubmitting} />
        </>
      ) : (
        <RequestSubmitted />
      )}
    </Layout>
  );
}
