/**
 * Creates a text button.
 *
 * @param {string} datatestid The test id used for our testing frameworks.
 * @param {function} onClick The callback function called when a button click event occurs.
 * @param {string} title The text inside the button.
 */
export const TextButton = ({ datatestid, onClick = () => {}, title }) => {
  return (
    <button
      data-testid={datatestid}
      className="text-btn"
      onClick={() => onClick()}
    >
      {title}
    </button>
  );
};
