import { Routes, Route } from 'react-router-dom';
import { FormContextProvider } from 'contexts/FormContext';
import KrMigrationFormPage from './migration-form';

export default function KrMigrationRoutes() {
  return (
    <FormContextProvider market="kr">
      <Routes>
        <Route path="migration-form" element={<KrMigrationFormPage />} />
      </Routes>
    </FormContextProvider>
  );
}
