import { parseMigrationDataByMarket } from "helpers/form";
import { post } from "./request";

export const formApi = {
  saveMigrationData: async ({ market, locale, rep, form }) => {
    const body = parseMigrationDataByMarket(market, locale, rep, form);
    try {
      const res = await post("/api/saveMigrationData", { ...body });
      return res;
    } catch {
      console.error("Problem saving migration form data.");
    }
  },
  savePaidMigrationData: async ({ market, locale, rep, form }) => {
    const body = parseMigrationDataByMarket(market, locale, rep, form);
    return post("/api/saveMigrationDataPaid", { ...body });
  },
  createOrderMigration: async ({ rep }) => {
    const { RepDID } = rep;
    try {
      const res = await post("/api/createOrderMigration", { repDID: RepDID });
      return res;
    } catch {
      console.error("Problem creating the order for migration.");
    }
  },
  checkForUniquePhoneNumber: async ({ form }) => {
    try {
      const res = await post("/api/checkForUniquePhoneNumber", {
        phoneNumber: form.BillPhone,
      });

      if (res?.recordset?.length === 0) {
        return true;
      } else {
        return false;
      }
    } catch {
      console.error("Problem checking for a unique phone number.");
    }
  },
  validateTTNPhone: async ({ form }) => {
    try {
      const res = await post("/api/validateTTNPhone", {
        phone: form.BillPhone,
      });

      if (typeof res === "string") {
        return res === "Okay to use" ? true : false;
      } else {
        return false;
      }
    } catch {
      console.error("Problem validating phone number against TTN.");
    }
  },
};
