import { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentful } from 'hooks/useContentful';
import BlueSwitchCheckboxInput from 'components/common/inputs/BlueSwitchCheckboxInput';
import FormAddressText from 'components/common/texts/FormAddressText';
import SoAddressText from 'components/common/texts/SoAddressText';
import Modal from './Modal';

export default function ConfirmAddressModal({
  addressType,
  form,
  so,
  callback,
  close,
}) {
  // Custom Hooks
  const data = useContentful('2bOrqF0NGNLS4nG7xGpeua'); // Confirm Your Address Modal Info

  // State
  const [overwrite, setOverwrite] = useState(true);

  if (!data) {
    return null;
  }

  const onConfirm = () => {
    close();
    callback({ overwrite, so });
  };

  const {
    title,
    item1,
    item2,
    buttonText,
    richText: shippingText,
    richText1: billingText,
  } = data.fields;

  return (
    <Modal id="confirm-address-modal" cancelType={false}>
      <section className="modal-title">
        <h2>{title}</h2>
        <div className="text-center wide-desktop mt-20">
          {addressType === 'Ship' && documentToReactComponents(shippingText)}
          {addressType === 'Bill' && documentToReactComponents(billingText)}
        </div>
      </section>
      <section className="modal-content">
        <h3 className="light text-center">{item1}</h3>
        <div className="center">
          <BlueSwitchCheckboxInput
            inputId="CurrentAddress"
            inputName="CurrentAddress"
            inputAriaLabel="CurrentAddress"
            inputChecked={!overwrite}
            inputOnChange={() => setOverwrite(false)}
          >
            <FormAddressText addressType={addressType} form={form} />
          </BlueSwitchCheckboxInput>
        </div>
        <h3 className="light text-center">{item2}</h3>
        <div className="center">
          <BlueSwitchCheckboxInput
            inputId="SoAddress"
            inputName="SoAddress"
            inputAriaLabel="SoAddress"
            inputChecked={overwrite}
            inputOnChange={() => setOverwrite(true)}
          >
            <SoAddressText so={so} />
          </BlueSwitchCheckboxInput>
        </div>
      </section>
      <div className="center">
        <button
          data-testid={`${buttonText?.replace(/\W/g, '').toLowerCase()}_btn`}
          className="button"
          onClick={() => onConfirm()}
        >
          {buttonText}
        </button>
      </div>
    </Modal>
  );
}
