import { useEffect } from "react";
import BlueSwitchCheckboxInput from "components/common/inputs/BlueSwitchCheckboxInput";
import { useForm } from "contexts/FormContext";
import { useModal } from "contexts/ModalContext";

export default function FormFooter({ data, onFormSubmit, isSubmitting }) {
  // Contexts
  const {
    form: { TermsAndConditions, IsValid },
    inputOnChange,
    validateForm,
  } = useForm();
  const { open } = useModal();

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TermsAndConditions]);

  const { item2, item3, item4 } = data.fields;

  return (
    <>
      <BlueSwitchCheckboxInput
        inputId='TermsAndConditions'
        inputName='TermsAndConditions'
        inputAriaLabel='TermsAndConditions'
        inputChecked={TermsAndConditions}
        inputOnChange={inputOnChange}
        label={item2}
      />
      <button
        className='button'
        onClick={onFormSubmit}
        type='button'
        data-testid='main_btn'
        disabled={!IsValid || isSubmitting}
      >
        {item3}
      </button>
      <p className='mt-40 mb-20 small bottom'>
        <a
          data-testid={`${item4?.replace(/\W/g, "").toLowerCase()}_btn`}
          href='#top'
          onClick={(e) => {
            e.preventDefault();
            open({ type: "cancel", props: {
              overrideButtonUrl: data.overrideCancelButtonUrl
            } });
          }}
        >
          {item4}
        </a>
      </p>
    </>
  );
}
