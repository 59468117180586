import MarketFlag from "modules/language/MarketFlag";

export default function FlagHeader({ data, market }) {
  // Contentful
  const { title, item1 } = data.fields;
  const backgroundImage = require(`assets/images/${market}-bkg.jpg`);
  return (
    <section 
      className={`intro-flag center map mt-20 ${market}`}
      style={{backgroundImage:`url(${backgroundImage})` }}
    >
      <p className='text-center mt-5'>{title}</p>
      <h1 className='text-center wide-desktop'>
        {market !== "hkcb" && (
          <>
            <MarketFlag market={market} />
            {item1}
          </>
        )}
      </h1>
      <hr />
    </section>
  );
}
