import { isArray } from "lodash";
import { marketToPathMap } from "../constants/market";
import { get, post } from "./request";

export const addressApi = {
  validate: async ({ addressType, form, country }) => {
    // Countries that require outputs returned in their local language.
    const countriesWithLocalOutput = ["KOREA", "China"];

    // addressType must either equal 'Bill' or 'Ship' depending on the address.
    const body = {
      Address1: form[`${addressType}Street1`],
      Address2: form[`${addressType}Street2`],
      Address3: "",
      Address4: "",
      Address5: "",
      Locality: form[`${addressType}City`],
      AdministrativeArea: form[`${addressType}State`],
      PostalCode: form[`${addressType}PostalCode`],
      Country: country,
      OutputLanguage: countriesWithLocalOutput.includes(country)
        ? "LOCAL"
        : "LOCAL_ROMAN",
    };

    try {
      const res = await post("/api/addressValidationMigration", {
        ...body,
      });
      return res;
    } catch {
      console.error("Problem validating address with service objects.");
    }
  },
  getCountryStates: async (market) => {
    const country = Object.keys(marketToPathMap).find(key => marketToPathMap[key] === market);
    
    // If you're getting this error, you need to add the market into constants/market.js
    if (!country) {
      throw new Error(`Full country name not provided for market ${market}.`)
    }
    try {
      const res = await get(`/api/migration/states/${country}`);

      if (typeof res === "object") {
        return res;
      } else {
        return [];
      }
    } catch {
      throw new Error(`Problem retrieving states for ${country}.`);
    }
  },
};
