import { post } from './request';

export const guidApi = {
  validate: async ({ guid }) => {
    try {
      const res = await post('/api/validateByGuidMigrate', { guid });
      return res;
    } catch {
      console.error('Problem validating migration guid.');
    }
  },
};
