import React from 'react';
import { createRoot } from 'react-dom/client';
import { initGoogleTagManager } from 'api/gtm';
import { AppContextProvider } from 'contexts/AppContext';
import { ModalContextProvider } from 'contexts/ModalContext';
import 'styles/app.min.css';
import 'styles/enrollment.min.css';
import 'styles/migration.css';
import AppRoutes from './routes';
import reportWebVitals from './reportWebVitals';

// React 18 initialization.
const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <AppContextProvider>
    <ModalContextProvider>
      <AppRoutes />
    </ModalContextProvider>
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Initialization is dependent on environment variables being present.
initGoogleTagManager();
