import ShadowCard from "components/common/cards/ShadowCard";
import TextInput from "components/common/inputs/TextInput";
import { useApp } from "contexts/AppContext";
import { useForm } from "contexts/FormContext";
import { inputIdFromNameMap } from "helpers/form";
import RepData from "components/library/RepData";

export default function HkcbFormDistributorInfoSection({ data, config }) {
  const {
    state: { rep },
  } = useApp();
  const { form, inputOnChange, validateForm } = useForm();
  const {
    sections: { personal: inputNames },
    inputs,
  } = config;
  const { FirstName, LastName } = rep;
  const { text4, inputModelArray } = data.fields;
  const name = `${FirstName} ${LastName}`;
  // inputNames need to match the order of models coming from contentful.
  const inputOnBlurPatterns = inputNames.map(
    (name) => inputs?.[name]?.onBlurPattern
  );

  return (
    <ShadowCard>
      <div className='form-max flex-20 column'>
        <RepData data={[{ label: text4 }]} />
        {inputModelArray.map((inputModel, index) => {
          const inputWrapperClass =
            index === 0 ? "input-wrapper mt-20" : "input-wrapper";
          const inputName = inputNames[index];
          const inputId = inputIdFromNameMap[inputName] || inputName;
          const { name, helperText } = inputModel.fields;
          const type = inputs?.[inputName]?.type || "text";
          const inputProps = {
            onBlurPattern: inputOnBlurPatterns[index],
            inputWrapperClass,
            inputId,
            inputName,
            inputValue: form[inputId],
            inputOnChange,
            inputOnBlur: validateForm,
            label: name,
            helperText,
            key: inputId,
            type,
          };

          return <TextInput {...inputProps} />;
        })}
      </div>
    </ShadowCard>
  );
}
