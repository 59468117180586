import CnConfirmation from 'components/cn/CnConfirmation';
import Layout from 'components/common/layouts/Layout';
import { useContentful } from 'hooks/useContentful';

export default function CnMigrationRequestConfirmationPage() {
  const data = useContentful('4HEJeAbPYHiZrtSOAEJzZy'); // (Paid Version) China Request Confirmation Info

  if (!data) {
    return null;
  }

  return (
    <Layout data={data}>
      <CnConfirmation data={data} />
    </Layout>
  );
}
