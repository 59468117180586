import { useEffect } from "react";
import dayjs from "dayjs";
import ShadowCard from "components/common/cards/ShadowCard";
import TextInput from "components/common/inputs/TextInput";
import {
  tenToThirteenDigitsAndHyphenRegex,
  koreaDateRegex,
} from "constants/regex";
import { useForm } from "contexts/FormContext";
import { useApp } from "contexts/AppContext";

export default function KrFormPersonalInformationSection({ data }) {
  // Context
  const { form, inputOnChange, validateForm } = useForm();
  const {
    state: {
      rep: { DateofBirth },
    },
  } = useApp();

  useEffect(() => {
    if (DateofBirth) {
      inputOnChange({
        target: {
          value: dayjs(DateofBirth).format("YYYY/MM/DD"),
          id: "DateOfBirth",
        },
      });
    }
    // the ignore is intentional until inputOnChange can be memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DateofBirth, form.FirstName, form.LastName]);

  // Contentful
  const { title, inputModelArray } = data.fields;

  return (
    <ShadowCard label={title}>
      <div className='form-max flex-20 column'>
        <TextInput
          inputId='FirstName'
          inputName='fname'
          inputValue={form.FirstName}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          label={inputModelArray[0]?.fields?.name}
          helperText={inputModelArray[0]?.fields?.helperText}
          inputErrorText={inputModelArray[0]?.fields?.errorText}
          dynamicHelperText
          required
        />
        <TextInput
          inputId='LastName'
          inputName='lname'
          inputValue={form.LastName}
          inputOnChange={inputOnChange}
          inputOnBlur={validateForm}
          label={inputModelArray[1]?.fields?.name}
          helperText={inputModelArray[1]?.fields?.helperText}
          inputErrorText={inputModelArray[1]?.fields?.errorText}
          dynamicHelperText
          required
        />
        {!DateofBirth ||
        dayjs(DateofBirth).format("YYYY/MM/DD") === "1900/01/01" ? (
          <TextInput
            inputId='DateOfBirth'
            inputName='date-of-birth'
            inputValue={form.DateOfBirth}
            inputOnChange={inputOnChange}
            inputOnBlur={validateForm}
            onBlurPattern={koreaDateRegex}
            label={inputModelArray[2]?.fields?.name}
            helperText={inputModelArray[2]?.fields?.helperText}
            inputErrorText={inputModelArray[2]?.fields?.errorText}
            mask='inverseDate'
            required
          />
        ) : null}
        <TextInput
          inputId='ResidentRegistrationNumber'
          inputName='resident-registration-number'
          onBlurPattern={tenToThirteenDigitsAndHyphenRegex}
          inputValue={form.ResidentRegistrationNumber}
          inputOnChange={(e) => {
            const value = e.target.value;
            if (!value.includes("-") && value.length <= 13) {
              inputOnChange(e);
            } else if (value.includes("-") && value.length <= 14) {
              inputOnChange(e);
            }
          }}
          label={inputModelArray[3]?.fields?.name}
          helperText={inputModelArray[3]?.fields?.helperText}
          inputErrorText={inputModelArray[3]?.fields?.errorText}
          inputOnBlur={validateForm}
          maxLength={14}
          dynamicHelperText
          required
        />
      </div>
    </ShadowCard>
  );
}
