import { Routes, Route } from 'react-router-dom';
import { FormContextProvider } from 'contexts/FormContext';
import JpMigrationFormPage from './migration-form';

export default function JpMigrationRoutes() {
  return (
    <FormContextProvider market="jp">
      <Routes>
        <Route path="migration-form" element={<JpMigrationFormPage />} />
      </Routes>
    </FormContextProvider>
  );
}
