/**
 * Used to render a formatted address by parsing relevant fields in the form object.
 *
 * @param {addressType} string needs to be either "Bill" or "Ship" to determine which address fields to parse.
 * @returns a formatted address.
 */

export default function FormAddressText({ addressType, form }) {
  const Street1 = form[`${addressType}Street1`];
  const Street2 = form[`${addressType}Street2`];
  const City = form[`${addressType}City`];
  const State = form[`${addressType}State`];
  const PostalCode = form[`${addressType}PostalCode`];

  return (
    <p className="address">
      {Street1 && (
        <>
          {Street1}
          <br />
        </>
      )}
      {Street2 && (
        <>
          {Street2}
          <br />
        </>
      )}
      {City && (
        <>
          {City}
          <br />
        </>
      )}
      {State && (
        <>
          {State}
          <br />
        </>
      )}
      {PostalCode && PostalCode}
    </p>
  );
}
